import { Row, Col, Card, Select, Input,  } from "antd";
import React, { useEffect,  } from "react";
import { Link,  } from "react-router-dom";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
// import { Loader, EmptyComponents } from "../../common/components/";
import { AppConfig,  } from "../../config/AppConfig";

import dummyImage from "../../assets/images/templateNotFound.png";
import {   abbreviateNumber } from "../../Helpers";
// import { ApiRoutes } from "../../config/ApiRoutes";
const { Option } = Select;
// const Content = () => {
//   const history = useHistory();
//   const [notificationLoading, setNotificationLoading] = useState(true);
//   const [notificationData, setNotificationData] = useState([]);
//   const organizationId = getCampaignUserData().organizationId;
//   useEffect(() => {
//     getNotification();
//     // eslint-disable-next-line
//   }, []);
//   const getNotification = async () => {
//     const response = await new ApiHelper().FetchFromServer(
//       ApiRoutes.NOTIFICATION_LIST.service,
//       ApiRoutes.NOTIFICATION_LIST.url,
//       ApiRoutes.NOTIFICATION_LIST.method,
//       ApiRoutes.NOTIFICATION_LIST.authenticate,
//       { organizationId: organizationId, limit: limit, offset: 1 }
//     );
//     if (response.code === 200) {
//       setNotificationData(response.data.data);
//     } else {
//       message.error({
//         content: response.messages || response.error,
//         duration: 2
//       });
//     }
//     setNotificationLoading(false);
//   };
//   const readNotification = async (notificationId, read, campaignId = "") => {
//     await new ApiHelper().FetchFromServer(
//       ApiRoutes.NOTIFICATION_STATUS.service,
//       ApiRoutes.NOTIFICATION_STATUS.url,
//       ApiRoutes.NOTIFICATION_STATUS.method,
//       ApiRoutes.NOTIFICATION_STATUS.authenticate,
//       undefined,
//       { organizationId: organizationId, notificationId: notificationId, read }
//     );
//     if (campaignId !== "")
//       history.push(`/join-campaign/detail?campaignId=${campaignId}`);
//     else
//       message.success({
//         content: "Read marked successfully.",
//         duration: 2
//       });
//   };
//   return (
//     <div className="notificationBox">
//       <header>
//         <h3 className="mb-0">Notifications</h3>
//         <p className="mb-0">
//           <Link onClick={() => readNotification("", true)}>
//             Mark all as read
//           </Link>
//         </p>
//       </header>
//       <main className="scrollbar">
//         {notificationLoading ? (
//           <section
//             className="text-center"
//             style={{
//               height: "80vh",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center"
//             }}
//           >
//             <Loader />
//           </section>
//         ) : notificationData.length > 0 ? (
//           notificationData &&
//           notificationData.map((notification) => {
//             const time = timeDiff(notification.date);
//             const additionalData = JSON.parse(notification.additionalData);
//             return (
//               <section
//                 className="notificationData shadow-sm"
//                 key={notification.notificationId}
//                 onClick={() =>
//                   readNotification(
//                     notification.notificationId,
//                     false,
//                     additionalData.CampaignId
//                   )
//                 }
//               >
//                 <div className="icon">
//                   <img
//                     src={
//                       notification.imageName
//                         ? notification.imageName.startsWith("https://")
//                           ? notification.imageName
//                           : `${AppConfig.API_ENDPOINT}/${notification.imageName}`
//                         : dummyImage
//                     }
//                     onError={(e) => {
//                       e.target.onerror = null;
//                       e.target.src = dummyImage;
//                     }}
//                     alt="logo"
//                   />
//                 </div>
//                 <div className="notificationContent">
//                   <p className="title mb-0">{notification.notification}</p>
//                   <p className="date mb-0">
//                     <small>
//                       {moment(notification?.date).format("MM-DD-YYYY HH:mm:ss")}
//                     </small>
//                     <small>{time} ago</small>
//                   </p>
//                 </div>
//               </section>
//             );
//           })
//         ) : (
//           // </InfiniteScroll>
//           <EmptyComponents
//             title="Notification"
//             message="No Notification Available."
//           />
//         )}
//       </main>
//       <footer>
//         <p className="mb-0">
//           <Link to={`/join-campaign/notifications`}>
//             View all notifications
//           </Link>
//         </p>
//       </footer>
//     </div>
//   );
// };
const List = ({ campaign, handleSearch, brandData }) => {
  // const [notificationStatus, setNotificationStatus] = useState(true);
  // const organizationId = getCampaignUserData().organizationId;
  useEffect(() => {
    // getNotificationReadStatus();
    // eslint-disable-next-line
  }, []);
  // const getNotificationReadStatus = async () => {
  //   const response = await new ApiHelper().FetchFromServer(
  //     ApiRoutes.NOTIFICATION_COUNT.service,
  //     ApiRoutes.NOTIFICATION_COUNT.url,
  //     ApiRoutes.NOTIFICATION_COUNT.method,
  //     ApiRoutes.NOTIFICATION_COUNT.authenticate,
  //     { organizationId: organizationId, limit: limit, offset: 1 }
  //   );
  //   if (response.code === 200) {
  //     //    setNotificationStatus(response.data.count > 0 ? true : false);
  //   } else {
  //     message.error({
  //       content: response.messages || response.error,
  //       duration: 2
  //     });
  //   }
  // };
  return (
    <div className="list">
      <Row gutter={[24, 24]} className="joinFilterSec">
        <Col xl={6} lg={6} xs={24}>
          <Input
            placeholder="Search Campaigns"
            prefix={<SearchOutlined />}
            className="mb-10"
            name="search"
            onChange={handleSearch}
            allowClear
          />
        </Col>
        <Col xl={5} lg={6} xs={24}>
          <Select
            mode="multiple"
            allowClear
            showArrow
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Sort By : Brand"
            maxTagCount="responsive"
            style={{ width: "100%" }}
            onChange={(e) => handleSearch(e, "brand")}
          >
            {brandData.map((item, key) => {
              return (
                <Option key={key} value={item.organizationId}>
                  {item.organizationName}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xl={5} lg={6} xs={24}>
          <Select
            allowClear
            placeholder="Sort By : Status"
            maxTagCount="responsive"
            style={{ width: "100%" }}
            onChange={(e) => handleSearch(e, "status")}
            className="statusDropdown"
            defaultValue="unblock"
          >
            {/* <Option value="Published"><span style={{ display: 'flex', alignItems: 'center' }}><span style={{ backgroundColor: '#FB984A', width: '10px', height: '10px', borderRadius: '50%' }}></span> <span style={{ marginLeft: '5px' }}>Published</span></span></Option> */}
            <Option value="unblock">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: "#50CD89",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%"
                  }}
                ></span>{" "}
                <span style={{ marginLeft: "5px" }}> Active</span>
              </span>
            </Option>
            <Option value="expired">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: "#ff4d4f",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%"
                  }}
                ></span>{" "}
                <span style={{ marginLeft: "5px" }}> Expired</span>
              </span>
            </Option>
            <Option value="block">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: "#7f8c9c",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%"
                  }}
                ></span>{" "}
                <span style={{ marginLeft: "5px" }}> Blocked</span>
              </span>
            </Option>
          </Select>
        </Col>
        {/* <Col xl={1} lg={1} xs={24}>
          <Badge dot={notificationStatus} className="notificationIconSec">
            <Popover placement="bottomRight" content={Content} trigger="click">
              <BellOutlined />
            </Popover>
          </Badge>
        </Col> */}
      </Row>

      <>
        <Row gutter={[24, 24]}>
          {campaign.map((item, key) => {
            return (
              <Col xl={6} lg={8} xs={24} key={key}>
                <Link
                  to={`/join-campaign/detail?campaignId=${item.CampaignId}`}
                >
                  <Card className="campaignListSec shadow" hoverable>
                    <header className="innerBox">
                      <h3 className="text-ellipse1">{item.CampaignName}</h3>
                      <span>
                        {item.currencySign}
                        {abbreviateNumber(item.TotalOffer)}
                      </span>
                      <img
                        src={
                          item.logo
                            ? item.logo.startsWith("https://")
                              ? item.logo
                              : `${AppConfig.API_ENDPOINT}/${item.logo}`
                            : dummyImage
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = dummyImage;
                        }}
                        alt=""
                        width="70"
                      />
                      <div
                        className={`statusPoint ${item.Cstatus === "2" ? "Published" : "Blocked"
                          }`}
                      ></div>
                      {/* <div className="joinedSec">Joined</div> */}
                      {item.campaignJoin > 0 ? (
                        <div className="joinedWrapper">
                          <div className="ribbon">Joined</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </header>
                    <div className="halfCircleLeft"></div>
                    <div className="halfCircleRight"></div>
                    <footer>
                      <p>
                        <strong>
                          {moment(item.CStartDate).format("Do MMM")} -{" "}
                          {moment(item.CEndDate).format("Do MMM")}
                        </strong>
                      </p>
                    </footer>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </>
    </div>
  );
};

export { List };
