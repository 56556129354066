import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Layout, Form, message, Row, Col, Button } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { MultiStore } from "../../modules/public/";
import "../../modules/public/public.less";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

const { Content } = Layout;

const Store = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const brandData = getCampaignUserData();
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  // const [isSetLocation, setIsSetLocation] = useState(true);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [inventoryData, setInventoryData] = useState([]);
  const [noOfRows, setNoOfRows] = React.useState([Math.random()]);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: queryString.get("campaignId")
      }
    );
    const inventoryResponse = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_PRODUCT_LIST.service,
      ApiRoutes.STORE_PRODUCT_LIST.url,
      ApiRoutes.STORE_PRODUCT_LIST.method,
      ApiRoutes.STORE_PRODUCT_LIST.authenticate,
      {
        organizationId: queryString.get("organizationId"),
        campaignId: queryString.get("campaignId")
      }
    );
    // setLoading(false);
    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        setCampaignData(response.data);
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    if (inventoryResponse.code === 200) {
      if (inventoryResponse.data === undefined) {
        setInventoryData(null);
      } else {
        for (let x in inventoryResponse.data.data) {
          if (inventoryResponse.data.data[x].currentQty === '0.00') {
            inventoryResponse.data.data[x].currentQty = "";
          }
        }
        setInventoryData(inventoryResponse.data.data);
        form.setFieldsValue([
          {
            products: inventoryResponse.data.data
          }
        ]);
      }
    }
  };

  const onFinishForSingle = async () => {
    const values = await form.validateFields();
    let checkQty;
    for (let x in values[0].products) {
      if (values[0].products[x].currentQty) {
        checkQty = values[0].products[x].currentQty;
      }
    }
    if (!checkQty) {
      message.error({
        content: "Please fill stock quantity of atleast one product.",
        duration: 5,
      });
      return;
    }
    setIsLoadingSave(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_PRODUCT_ASSIGN.service,
      ApiRoutes.STORE_PRODUCT_ASSIGN.url,
      ApiRoutes.STORE_PRODUCT_ASSIGN.method,
      ApiRoutes.STORE_PRODUCT_ASSIGN.authenticate,
      undefined,
      {
        organizationId: queryString.get("organizationId"),
        campaignId: queryString.get("campaignId"),
        products: JSON.stringify(values[0].products)
      }
    );
    setIsLoadingSave(false);
    if (response.code === 200) {
      history.push(`/public/success`);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };

  const onFinishForMultiple = async (val) => {
    const values = await form.validateFields();
    let checkQty;
    for (let x in values[noOfRows.length - 1].products) {
      if (values[noOfRows.length - 1].products[x].currentQty) {
        checkQty = values[noOfRows.length - 1].products[x].currentQty;
      }
    }
    if (!checkQty) {
      message.error({
        content: "Please fill stock quantity of at least one product.",
        duration: 5,
      });
      return;
    }
    if (isLoadingAdd || isLoadingSave) return false;
    if (val === 1) setIsLoadingAdd(true);
    if (val === 2) setIsLoadingSave(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_ADD.service,
      ApiRoutes.STORE_ADD.url,
      ApiRoutes.STORE_ADD.method,
      ApiRoutes.STORE_ADD.authenticate,
      undefined,
      {
        organizationId: queryString.get("organizationId"),
        campaignId: queryString.get("campaignId"),
        countryId: brandData.country,
        ...values[noOfRows.length - 1],
        latitude,
        longitude,
        products: JSON.stringify(values[noOfRows.length - 1].products)
      }
    );
    setIsLoadingAdd(false);
    setIsLoadingSave(false);
    if (response.code === 200) {
      if (val === 1) {
        setNoOfRows([...noOfRows, Math.random()]);
        form.setFieldsValue({
          [`${noOfRows.length}`]: { products: inventoryData }
        });
      } else {
        history.push(`/public/success`);
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };

  return (
    <>
      <Layout>
        <Content
          style={{ minHeight: 200, padding: "80px", background: "#fff" }}
        >
          <Form layout="vertical" className="merchantRegisterForm" form={form}>
            {noOfRows.map((i, key) => {
              return (
                <MultiStore
                  form={form}
                  campaignData={campaignData}
                  // isSetLocation={isSetLocation}
                  brandData={brandData}
                  inventoryData={inventoryData}
                  rowNum={key}
                  disabled={key === noOfRows.length - 1 ? false : true}
                  merchantStoreType={queryString.get("merchantStoreType")}
                  loading={loading}
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  campaignSelectState={campaignData ? campaignData.stateCity : []}
                />
              );
            })}

            <Row className="storeRegisterBox" gutter="24">
              <Col xs={24} xl={24} className="btnBox p-0 mt-5">
                <Form.Item className="mb-0">
                  {queryString.get("merchantStoreType") === "singleStore" ? (
                    <>
                      {isLoadingSave ? (
                        <Button
                          loading
                          style={{
                            backgroundColor: brandData ? brandData.primary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.primary : ''
                          }}
                        >
                          Save &amp; Exit
                        </Button>
                      ) : (
                        <Button
                          onClick={onFinishForSingle}
                          style={{
                            backgroundColor: brandData ? brandData.primary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.primary : ''
                          }}
                        >
                          Save &amp; Exit
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {isLoadingAdd ? (
                        <Button
                          loading
                          className="mr-3"
                          style={{
                            backgroundColor: brandData ? brandData.secondary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.secondary : ''
                          }}
                          onClick={async () => {
                            setNoOfRows([...noOfRows, Math.random()]);
                            form.setFieldsValue({
                              [`${noOfRows.length}`]: {
                                products: inventoryData
                              }
                            });
                          }}
                        >
                          Save &amp; Add more store
                        </Button>
                      ) : (
                        <Button
                          onClick={() => onFinishForMultiple(1)}
                          className="mr-3"
                          style={{
                            backgroundColor: brandData ? brandData.secondary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.secondary : ''
                          }}
                        >
                          Save &amp; Add more store
                        </Button>
                      )}
                      {isLoadingSave ? (
                        <Button
                          loading
                          style={{
                            backgroundColor: brandData ? brandData.primary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.primary : ''
                          }}
                        >
                          Save &amp; Exit
                        </Button>
                      ) : (
                        <Button
                          onClick={() => onFinishForMultiple(2)}
                          style={{
                            backgroundColor: brandData ? brandData.primary : '',
                            color: "#fff",
                            borderColor: brandData ? brandData.primary : ''
                          }}
                        >
                          Save &amp; Exit
                        </Button>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </>
  );
};
export default Store;
