import { Row, Col, Input, Form, DatePicker } from "antd";
import React from "react";
import moment from "moment";
// import { AppConfig } from "../../config/AppConfig";
import Template1 from "../../assets/images/Template/campaign1.png";
import { EmptyComponents } from "../../common/components/";
import { abbreviateNumber } from "../../Helpers";

const dateFormat = "YYYY/MM/DD";
const PreviewCampaign = (props) => {
  const { currency } = props;
  const [data, setData] = React.useState(props.formData);
  const [products, setProducts] = React.useState(props.products);
  React.useEffect(() => {
    setData(props.formData);
    setProducts(props.products);
  }, [props.formData, props.products]);
  console.log('data========00',data)
  return (
    <>
      <Row gutter="24">
        <Col xl={16} xs={24}>
          <div className="left">
            <Row gutter="24" className="OfferValueBox mb-5">
              <Col xl={8} xs={24}>
                <div className="innerBox denominationBox">
                  <h5 className="mb-0">Denomination</h5>
                  <p className="mb-0">
                    {currency}
                    {data.denomination || 0}
                  </p>
                </div>
              </Col>
              <Col xl={8} xs={24}>
                <div className="innerBox voucherBox">
                  <h5 className="mb-0">No of Vouchers</h5>
                  <p className="mb-0">{data.noOfVoucher || 0}</p>
                </div>
              </Col>
              <Col xl={8} xs={24}>
                <div className="innerBox valueBox">
                  <h5 className="mb-0">Total Offer Value</h5>
                  <p className="mb-0">
                    {currency}
                    {parseInt(data.totalOfferValue)>0 ? abbreviateNumber(data.totalOfferValue) : 0}
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter="24" className="campaignInformationCard">
              <Col xl={8} xs={24}>
                <Form.Item label="Campaign Name" name="campaignName">
                  <Input type="text" value={data.campaignName} disabled />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker
                    defaultValue={moment(data.startDate, dateFormat)}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item label="End Date" name="endDate">
                  <DatePicker
                    defaultValue={moment(data.endDate, dateFormat)}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="productsBox">
              <Row gutter="48">
                <Col xl={24} xs={24}>
                  <h3 className="heading">Products</h3>
                </Col>
                {data.templateProducts.length > 0 ? (
                  products &&
                  products.map((product, key) => {
                    return data.templateProducts.includes(
                      product.organizationProductId
                    ) ? (
                      <Col xl={8} xs={24} key={key}>
                        <div className="innerBox">
                          <img src={`${product.imageName}`} alt="icon" />
                          <div className="details">
                            <h4
                              className="text-ellipse1"
                              title={product.productCodeOveride}
                            >
                              {product.productCodeOveride}
                            </h4>
                            <p>
                              UPC | <span>{product.upc}</span>
                            </p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      // <p key={key}>{product.productCodeOveride}</p>
                      ""
                    );
                  })
                ) : (
                  <EmptyComponents title="" message="No Product Selected" />
                )}
              </Row>
            </div>
          </div>
        </Col>
        <Col xl={8} xs={24}>
          <div className="right">
            <Row>
              <Col xl={24} xs={24} className="stateBox mb-5">
                <label className="heading">Selected States</label>
                <div className="inner scrollbar">
                  {data.state.length > 0 ? (
                    props.state &&
                    props.state.map((stateDataPre, key) => {
                      return data.state.includes(stateDataPre.stateId) ? (
                        <p key={key}>{stateDataPre.stateName}</p>
                      ) : (
                        ""
                      );
                    })
                  ) : (
                    <EmptyComponents
                      title="State"
                      message="No State Selected"
                    />
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
                <Col xl={24} xs={24} className="stateBox mb-5">
                  <label className="heading">Selected City</label>
                  <div className="inner scrollbar">
                    {cityData.map((cityDataPre) => (
                      <p>{cityDataPre.cityName}</p>
                    ))}
                  </div>
                </Col>
              </Row> */}
            <Row gutter="24" className="templateBox mb-5">
              <Col xl={24} xs={24}>
                <label className="heading">Template</label>
                <div className="previewBox">
                  {data?.customImage ? (
                    <img src={data?.customImage || Template1} alt="Icon" />
                  ) : data?.templateType===2 ? "Default Template Selected" :(
                    "No Template Select"
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export { PreviewCampaign };
