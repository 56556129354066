import React, { useState } from "react";
import { Layout, Row, Col, Input, Space, message, Card, Form, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { PageTitle } from "../../common/components/layout/";
import "../../modules/campaigns/campaigns.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
// import { Loader } from "../../common/components/";

const { Content } = Layout;
const title = "Change Password";
const ChangePassword = () => {
  const [form] = Form.useForm();
  // const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async () => {
    const values = await form.validateFields();
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_PASSWORD.service,
      ApiRoutes.CHANGE_PASSWORD.url,
      ApiRoutes.CHANGE_PASSWORD.method,
      ApiRoutes.CHANGE_PASSWORD.authenticate,
      undefined,
      values
    );
    setIsLoading(false);
    if (response.code === 200) {
      form.resetFields();
      message.error({
        content: response.messages[0],
        duration: 2,
      });
      message.success({
        content: response.messages[0],
        duration: 2,
      });
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    //  setLoader(false);
  };
  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200, margin: "0px 25px" }}
        id="Campaigns"
      >
        <Row>
          <Col lg={24} xs={24}>
            <div style={{ margin: "0px 14px 14px 14px" }}>
              <Row className="campaignAddBox" gutter="24">
                <Col xl={24} xs={24}>
                  <Form layout="vertical" form={form} validateTrigger='onSubmit'>
                    <div>
                      <Card className="campaignInformationCard showWhenChecked" style={{borderRadius:'10px'}} >
                        <Row gutter="24">
                          <Col xl={8} xs={24}>
                            <Form.Item
                              label="Current Password"
                              name="currentPassword"
                              rules={[
                                {
                                  required: true,
                                  message: "Current password is required",
                                },
                              ]}
                            >
                              <Input.Password type="password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />

                            </Form.Item>
                          </Col>
                          <Col xl={8} xs={24}>
                            <Form.Item
                              label="New Password"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: 'New password is required',
                                },
                                {
                                  min: 6,
                                  message: 'Minimum 6 characters are required',
                                },
                                {
                                  max: 15,
                                  message: 'Maximum 15 characters are allow',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue('currentPassword') !== value) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject("Old password and the new password cannot be same.")
                                  },
                                }),
                              ]}
                            >
                              <Input.Password type="password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                          </Col>
                          <Col xl={8} xs={24}>
                            <Form.Item
                              label="Confirm Password"
                              name="confirmPassword"
                              rules={[
                                {
                                  required: true,
                                  message: 'Confirm password is required',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject("New password and confirm password does not match")
                                  },
                                }),
                              ]}
                            >
                              <Input.Password type="password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                          </Col>
                          <Col
                            xl={24}
                            xs={24}
                          >
                            {isLoading ? (
                              <Button type="primary" loading style={{ float: 'right' }}>
                                Submit
                              </Button>
                            ) : (
                              <Button type="primary" onClick={() => onFinish()} style={{ float: 'right' }}>
                                Submit
                              </Button>
                            )}
                          </Col>
                        </Row>

                      </Card>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default ChangePassword;
