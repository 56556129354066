import { Row, Col, Input, Form } from "antd";
const Addinventory = ({ inventoryData, form }) => {
  return (
    <>
      <Row className="addInventoryBox" gutter="24">
        <Col xl={24} xs={24}>
          <Form layout="vertical" name="" className="innerBox">
            <h1>Add Inventory</h1>
            <Row>
              <Col xl={24} lg={24} xs={24}>
                <section className="campaingInventory">
                  <Form
                    layout="vertical"
                    name="productEdit"
                    autoComplete="off"
                    // onFinish={onFinish}
                    form={form}
                    initialValues={{ products: inventoryData }}
                  >
                    <Row gutter={[24, 24]} className="w-100 scrollbar">
                      {inventoryData.length > 0 &&
                        inventoryData.map((item, key) => {
                          return (
                            <Col xl={12} lg={12} xs={24} key={key}>
                              <section className="innerBox">
                                <img
                                  src={item.imageurl}
                                  alt=""
                                  width="80"
                                  height="80"
                                />
                                <div className="contentBox">
                                  <div className="text-ellipse1">
                                    <h3 className="text-ellipse1">
                                      {item.productCodeOveride}{" "}
                                    </h3>
                                    <p className="mb-1">
                                      <span>UPC | </span>
                                      {item.upc}
                                    </p>
                                  </div>
                                  <div className="stockSec ml-auto">
                                    <Form.Item
                                      // noStyle
                                      name={["products", key, "currentQty"]}
                                      rules={[
                                        {
                                          pattern: new RegExp(/^[0-9]{0,5}$/i),
                                          message: "Invalid stock"
                                        }
                                      ]}
                                      style={{ minHeight: 0 }}
                                    >
                                      <Input
                                        type="number"
                                        placeholder="Enter Stock..."
                                        style={{ width: '100%' }}
                                        pattern="[0-9]"
                                        maxlength="5"
                                        rules={[
                                          {
                                            pattern: new RegExp(/^[0-9]{0,5}$/i),
                                            message: "Invalid stock"
                                          }
                                        ]}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name={["products", key, "productId"]}
                                      hidden={true}
                                    >
                                      <Input />
                                    </Form.Item>
                                    <Form.Item
                                      name={[
                                        "products",
                                        key,
                                        "organizationProductId"
                                      ]}
                                      hidden={true}
                                    >
                                      <Input />
                                    </Form.Item>
                                    <Form.Item
                                      name={[
                                        "products",
                                        key,
                                        "productInventoryId"
                                      ]}
                                      hidden={true}
                                    >
                                      <Input />
                                    </Form.Item>
                                    <Form.Item
                                      name={["products", key, "productPriceId"]}
                                      hidden={true}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </div>
                                </div>
                              </section>
                            </Col>
                          );
                        })}
                    </Row>
                  </Form>
                </section>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export { Addinventory };
