import { Row, Col, Button } from "antd";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { AppConfig } from "../../config/AppConfig";
import { getCampaignUserData } from "../../Helpers";
// import Successful from '../../assets/images/Join/successful.png'
const Success = () => {
  const brandData = getCampaignUserData();
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={8} lg={8} xs={24} offset={8}>
          <section className="successSec shadow">
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <motion.div
              className="container"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                delayChildren: 0.9,
                staggerChildren: 0.8
              }}
            >
              <h1 className="mt-5">Successfully Joined!</h1>
              <p>You have Successfully joined the campaign</p> 
              <Link to={`/`}>
                <Button type="primary" className="btnDone" style={{width:"100%"}}>
                  Done
                </Button>
              </Link>

              <a
                href={`${AppConfig.JOIN_LOGIN_URL}`}
                className="joinBtn ant-btn"
                style={{
                  marginRight: "10px",
                  marginTop: "10px",
                  width:"100%",
                  backgroundColor: brandData ? brandData.secondary : "",
                  color: "#fff",
                  borderColor: brandData ? brandData.secondary : ""
                }}
              >
                Sign In
              </a>
            </motion.div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export { Success };
