import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Popover, Tabs } from "antd";
// import { CloseOutlined } from "@ant-design/icons";
// import FrontendLogin from "../../../../modules/frontend/Login";
import FrontendLoginBusiness from "../../../../modules/frontend/LoginBusiness";
import Logo from "../../../../assets/images/Frontend/logo.png";
import "./header.less";
const { TabPane } = Tabs;
const FrontendHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector("#header");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <header id="header">
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top py-4">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="logo" className="klogo d-inline" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse" id="navbarCollapse">
            <ul className="navbar-nav mx-auto  mb-md-0 header-navbar">
              <li className={splitLocation[1] === "" ? "nav-item  pr-5 active" : "nav-item  pr-5"}>
                <Link to="/" className="nav-link">
                  Stashers
                </Link>
              </li>
              <li  className={splitLocation[1] === "business" ? "nav-item active" : "nav-item"}>
                <Link to="/business" className="nav-link">
                  Businesses
                </Link>
              </li>
              <li className={splitLocation[1] === "brand" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/brand">
                  Brands
                </Link>
              </li>
              <li className={splitLocation[1] === "contact" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <div className="d-flex login-btn-section ml-auto">
              <Popover
                placement="bottomRight"
                content={
                  <>
                    {/* <Link className="btn-close">
                      <CloseOutlined />
                    </Link> */}
                    <Tabs defaultActiveKey="1" type="card">
                      <TabPane tab="Login as Brand" key="1">
                        <FrontendLoginBusiness  type="1" />
                      </TabPane>
                      <TabPane tab="Login as Business" key="2">
                        <FrontendLoginBusiness  type="2" />
                      </TabPane>
                    </Tabs>
                  </>
                }
                title="Login"
                trigger="click"
              >
                <Link className="btn">
                  <span>Login</span>
                </Link>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default FrontendHeader;
