import React, { useState } from "react";
import { Row, Col, Button, Input, Card, Modal } from "antd";
import { RightOutlined, CloudUploadOutlined } from "@ant-design/icons";
import ImageCropper from "../../common/components/imageCropper/ImageCropper";

import ImageP from "../../assets/images/Frontend/brand.png";
import Draggable from "react-draggable";
const { TextArea } = Input;

const CustomerTemplate = (props) => {
  const [cropImage, setCropImage] = useState(null);
  const [image, setImage] = useState({
    preview: props?.formData?.customImage || "",
    raw: props?.formData?.customImage || ""
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleChange = (e) => {
    if (e.target.files.length) {
      setCropImage(URL.createObjectURL(e.target.files[0]));
      //   setImage({
      //     preview: URL.createObjectURL(e.target.files[0]),
      //     raw: e.target.files[0]
      //   });
      let reader = new FileReader();
      let file = e.target.files[0];
      setIsModalVisible(true);

      reader.readAsDataURL(file);
    }
  };

  const changeValue = (e, key) => {
    props.setFormData({ [key]: e.target.value });
  };
  const [imageError, setImageError] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [isModalVisibleButton, setIsModalVisibleButton] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const trackPos = (data) => {
    setPosition({ x: data.x, y: data.y });
  };
  const showModal = () => {
    setIsModalVisibleButton(true);
  };

  const handleOk = () => {
    setIsModalVisibleButton(false);
  };

  const handleCancel = () => {
    setIsModalVisibleButton(false);
  };

  //   const customFileUpload = (status) => {
  //     props.setFormData({ templateType: status });
  //     setShowCustom(status);
  //   };
  return (
    <>
      <Card className="campaignInformationCard showWhenChecked" hoverable>
        <Row gutter="24">
          <Col xl={12} xs={24} className="templateUploadSec">
            <div
              className="customTemplateBox"
              style={{
                position: "relative",
                padding: 18
              }}
            >
              <div className="innerBox">
                <label
                  htmlFor="upload-button"
                  style={{ width: "100px", height: "100px" }}
                >
                  {image.preview ? (
                    <img
                      src={image.preview}
                      alt="dummy"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <>
                      <div className="ant-upload-select-picture-card">
                        <CloudUploadOutlined />
                        <h5 className="text-center">Upload custom template</h5>
                      </div>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
                <br />
              </div>
            </div>
          </Col>
          <Col xl={12} xs={24}>
            {image.preview ? (
              <div className="cTemplateSec">
                <div className="cTemplatePreview">
                  <div className="cTemplateButtonSec">
                    <div
                      className="box"
                      style={{
                        height: "450px",
                        width: "400px",
                        position: "relative",
                        overflow: "hidden",
                        padding: "0"
                      }}
                    >
                      <div style={{ height: "450px", width: "400px" }}>
                        <img
                          src={image.preview}
                          alt="dummy"
                          style={{ width: "100%" }}
                        />

                        <Draggable onDrag={(e, data) => trackPos(data)}>
                          <div className="box">
                            {/* <div>
                                    x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
                              </div> */}
                            <button
                              style={{ background: "#FB984A", color: "#fff" }}
                            >
                              Save to Phone
                            </button>
                          </div>
                        </Draggable>
                      </div>
                    </div>
                    <div className="btnFooter">
                      <Button type="primary" size="large" className="mr-4">
                        Preview Template
                      </Button>
                      <Button type="primary" size="large" onClick={showModal}>
                        Edit Button
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Modal
              title="Button color changes"
              visible={isModalVisibleButton}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p className="mb-0">Set button background color</p>
              <div className="colorPicker input-group mb-3">
                <input
                  type="text"
                  className="p-form-control"
                  placeholder="#000000"
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon1">
                    <input type="color" className="c-form-control" />
                  </span>
                </div>
              </div>
              <p className="mb-0">Set button text color</p>
              <div className="colorPicker input-group mb-3">
                <input
                  type="text"
                  className="p-form-control"
                  placeholder="#000000"
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <input type="color" className="c-form-control" />
                  </span>
                </div>
              </div>
            </Modal>
            {imageError ? (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">Please select your Product Image</div>
              </div>
            ) : (
              ""
            )}
          </Col>
          {cropImage ? (
            <ImageCropper
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              cropImage={cropImage}
              setImage={setImage}
            />
          ) : (
            ""
          )}
          <Col xl={24} xs={24}>
            <label
              onClick="onclickNext('step-4')"
              htmlFor="step-4"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"
            >
              Next <RightOutlined />
            </label>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { CustomerTemplate };
