import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, message, Row, Col, Button, Card, Form, Input } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { Loader } from "../../common/components/Loader";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import "../../modules/public/public.less";


const FindStore = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const [location, setLocation] = useState('');
  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode,
        type: 'voucher'
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (response.data.campaign.Cstatus === "1" || response.data.campaign.Cstatus === 1) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  const findStore = async () => {
    if (location) {
      // setLoading(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.FIND_STORE_LOCATION.service,
        ApiRoutes.FIND_STORE_LOCATION.url,
        ApiRoutes.FIND_STORE_LOCATION.method,
        ApiRoutes.FIND_STORE_LOCATION.authenticate,
        {
          location
        }
      );
      // setLoading(false);

      if (response.code === 200) {
        console.log('response.data', response.data);
        setStoreData(response.data.data);
      } else {
        setStoreData([]);
      }
    }
    else {
      setStoreData([]);
    }
  };

  const findCurrentLocation = async () => {
    let currAddress = "Indore, Madhya Pradesh, India";
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position);
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  }

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAP_KEY,
    onPlaceSelected: (place) => console.log(place)
  })

  return (
    <>
      <Layout>
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) :
          <Row gutter={[48, 48]} className="campaignDetailSec">
            <Col xs={8} xl={8}>
              <div>
                <input ref={ref} /><br /><br /><br /><br /><br />
                <Autocomplete
                  apiKey={process.env.REACT_APP_MAP_KEY}
                  onChange={(place) => {
                    console.log('place', place);
                  }}
                  onPlaceSelected={(place) => {
                    console.log(place);
                    if (place.name) {
                      setLocation(place.name);
                    }
                    else {
                      setLocation(place.formatted_address);
                    }
                  }}
                />
              </div>
              <Button onClick={findStore}>Search</Button>
              <Button onClick={findCurrentLocation}>Find Current Location</Button>
              {storeData.length > 0 && storeData.map((item, key) => {
                return <>
                  <Card key={key}>
                    <p>
                      Organization Name: {item.organizationName}<br />
                      Address Line 1: {item.addressLine1}
                    </p>
                    <Button>Directions</Button>&nbsp;&nbsp;&nbsp;
                    <Button>View Page</Button>
                  </Card>
                </>
              })}
            </Col>
            <Col xs={16} xl={16}>
              Map
            </Col>
          </Row>
        }
      </Layout>
    </>
  );
};
export default FindStore;
