import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Form, message, notification } from "antd";
import { AddOtp } from "../../modules/public";
import "../../modules/public/public.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { Loader } from "../../common/components/Loader";

const { Content } = Layout;

const Otp = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const search = useLocation().search;
  const brandData = getCampaignUserData();
  const queryString = new URLSearchParams(search);
  // const [code, setCode] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const onFinish = async () => {
    const values = await form.validateFields();

    if (values.otp.length === 5) {
      setIsLoading(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.VERIFY_OTP.service,
        ApiRoutes.VERIFY_OTP.url,
        ApiRoutes.VERIFY_OTP.method,
        ApiRoutes.VERIFY_OTP.authenticate,
        undefined,
        { organizationId: queryString.get("organizationId"), otp: values.otp }
      );
      setIsLoading(false);
      if (response.code === 200) {
        notification.success({
          message: 'OTP verified successfully',
        });
        history.push(
          `/public/verify-account?campaignId=${queryString.get(
            "campaignId"
          )}&organizationId=${queryString.get(
            "organizationId"
          )}&merchantStoreType=${queryString.get("merchantStoreType")}`
        );
      } else {
        message.error({
          content: response.messages[0],
          duration: 2
        });
      }
    }
  };

  const resendOtp = async () => {
    setLoading(true)
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_OTP.service,
      ApiRoutes.RESEND_OTP.url,
      ApiRoutes.RESEND_OTP.method,
      ApiRoutes.RESEND_OTP.authenticate,
      undefined,
      { organizationId: queryString.get("organizationId") }
    );
    if (response.code === 200) {
      message.success({
        content: "OTP sent successfully",
        duration: 2
      });
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    setLoading(false)
  };

  return (
    <>
      <Layout>
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Content
            style={{ minHeight: 200, padding: "80px", background: "#fff" }}
          >
            <AddOtp
              form={form}
              onFinish={onFinish}
              resendOtp={resendOtp}
              brandData={brandData}
              isLoading={isLoading}
              email={queryString.get("email")}
            />
          </Content>)}
      </Layout>
    </>
  );
};
export default Otp;
