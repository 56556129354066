import { Row, Col, Button, Steps } from "antd";
import React, { useEffect } from "react";
import { Storeinformation } from "./StoreInformation"
import { Verifyaccount } from "./VerifyAccount"
import { Addinventory } from "./AddInventory"
const { Step } = Steps;
const Inventory = (props) => {
    const { code, setCode, onFinish, inventoryData, isLoading, form, storeForm, handleStoreSubmit, stateData, cityData, handleStateChange, currentState, setCurrentState, setLatitude, setLongitude, isCampaignJoin, initialLocation, isEdit, onChangeEdit, handleCityChange, campaignData, accountStatus } = props;
    const steps = [
        {
            title: 'Store information',
            content: <Storeinformation storeForm={storeForm} stateData={stateData} campaignSelectState={campaignData.stateCity} cityData={cityData} handleStateChange={handleStateChange} setLatitude={setLatitude} setLongitude={setLongitude} initialLocation={initialLocation} isEdit={isEdit}
                onChangeEdit={onChangeEdit} handleCityChange={handleCityChange} />,
        },
        {
            title: 'Verify account',
            content: <Verifyaccount code={code} setCode={setCode} accountStatus={accountStatus} />,
        },
        {
            title: 'Add inventory',
            content: <Addinventory inventoryData={inventoryData} form={form} />,
        },
    ];

    useEffect(() => {
        setCurrent(currentState);
        // eslint-disable-next-line
    }, [currentState]);

    const [current, setCurrent] = React.useState(0);

    const next = () => {
        setCurrent(current + 2);
    };

    const prev = () => {
        if (accountStatus === 'unverified') {
            setCurrent(current - 1);
            setCurrentState(current - 1);
        }
        else {
            setCurrent(current - 2);
            setCurrentState(current - 2);
        }
    };
    return (
        <>
            <Row className="joinLocationBox">
                <Col xl={24} xs={24}>
                    <div className="stepBox">
                        <div className="stepTitle">
                            <Steps current={current} direction="vertical">
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                        </div>
                        <div className={`stepsContent stepNo${current}`}>
                            {steps[current].content}
                            <div className="stepsAction">
                                {current < steps.length - 1 && (
                                    <>
                                        {current === 0 ?
                                            <>
                                                {isLoading ? (
                                                    <Button type="primary" loading>
                                                        Next
                                                    </Button>
                                                ) : (
                                                    <Button type="primary" onClick={() => handleStoreSubmit()}>
                                                        Next
                                                    </Button>
                                                )}
                                            </>
                                            : ''}
                                        {current === 1 && code !== '' ?
                                            <Button type="primary" onClick={() => next()}>
                                                Add Inventory
                                            </Button>
                                            : ''}
                                    </>
                                )}
                                {current === steps.length - 1 && (
                                    <>
                                        {isLoading ? (
                                            <Button type="primary" loading className="btnJoinCampaign">{campaignData && campaignData.campaign.campaignJoin > 0 ? 'Update Stock' : 'Join Campaign'}</Button>
                                        ) : (
                                            <Button type="primary" onClick={onFinish} className="btnJoinCampaign">{campaignData && campaignData.campaign.campaignJoin > 0 ? 'Update Stock' : 'Join Campaign'}</Button>
                                        )}
                                    </>
                                )}
                                {current > 0 && isCampaignJoin === false && (
                                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col xl={24} lg={24} xs={24}>
                    <section className="campaingInventory">
                        <Form
                            layout="vertical"
                            name="productEdit"
                            autoComplete="off"
                            onFinish={onFinish}
                            initialValues={{ products: inventoryData }}
                        >
                            <Row gutter={[24, 24]} className="w-100 scrollbar">
                                {inventoryData.length > 0 && inventoryData.map((item, key) => {
                                    return (
                                        <Col xl={12} lg={12} xs={24} key={key}>
                                            <section className="innerBox">
                                                <img
                                                    src={
                                                        item.imageName
                                                            ? item.imageName.startsWith('https://')
                                                                ? item.imageName
                                                                : `${AppConfig.API_ENDPOINT}/${item.imageName}`
                                                            : dummyImage
                                                    }
                                                    onError={e => {
                                                        e.target.onerror = null;
                                                        e.target.src = dummyImage;
                                                    }} alt="" width="100" height="90" />
                                                <div className="text-ellipse1">
                                                    <h3 className="text-ellipse1">Handcrafted beverages </h3>
                                                    <p><span>UPC | </span>{item.upc}</p>
                                                </div>
                                                <div className="stockSec ml-auto">
                                                    <Form.Item noStyle name={['products', key, 'currentQty']} >
                                                        <Input size="large" placeholder="Enter Stock..." style={{ width: 160 }} />
                                                    </Form.Item>
                                                    <Form.Item name={['products', key, 'organizationProductId']} hidden={true}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name={['products', key, 'productInventoryId']} hidden={true}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name={['products', key, 'productPriceId']} hidden={true}>
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            </section>
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Row gutter={[24, 24]}>
                                {inventoryData.length > 0 ?
                                    <Col xl={24} xs={24} className="mt-5 text-right">
                                        {isLoading ? (
                                            <Button type="primary" loading className="btnJoinCampaign">Join Campaign</Button>
                                        ) : (
                                            <Button type="primary" htmlType="submit" className="btnJoinCampaign">Join Campaign</Button>
                                        )}
                                    </Col> : ''
                                }
                            </Row>
                        </Form>
                    </section>
                </Col>
            </Row> */}
        </>
    );
};

export { Inventory };