import { Row, Col, Button, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AppConfig } from "../../config/AppConfig";
import { getCampaignUserData, abbreviateNumber } from "../../Helpers";
import dummyImage from "../../assets/images/templateNotFound.png";

const Details = ({
  campaignData,
  loading,
  handleCampaignStatus,
  handleBrandStatus,
  loadingBrandBtn,
  loadingCampaigndBtn
}) => {
  return (
    <>
      <Row gutter={[24, 24]} className="campaingView">
        <Col xl={18} lg={18} xs={24}>
          <section className="left">
            <section className="campaignHeading">
              <Skeleton avatar paragraph={{ rows: 1 }} loading={loading} active>
                <img
                  src={
                    campaignData && campaignData.campaign
                      ? campaignData.campaign.logo.startsWith("https://")
                        ? campaignData.campaign.logo
                        : `${AppConfig.API_ENDPOINT}/${campaignData.campaign.logo}`
                      : dummyImage
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyImage;
                  }}
                  alt=""
                  width="80"
                  height="80"
                />
                <div>
                  <h3>
                    {campaignData ? campaignData.campaign.CampaignName : ""}
                  </h3>
                  <p>
                    {campaignData
                      ? `${moment(campaignData.campaign.CStartDate).format(
                        "Do MMM"
                      )} - ${moment(campaignData.campaign.CEndDate).format(
                        "Do MMM"
                      )}`
                      : ""}
                  </p>
                </div>
                <div className="ml-auto d-flex justify-content-end flexWrap">
                  {campaignData &&
                    moment().format("YYYY-MM-DD") >
                    campaignData.campaign.CEndDate ? (
                    ""
                  ) : (
                    <Link className="mr-2"
                      to={`/join-campaign/inventory?campaignId=${campaignData ? campaignData.campaign.CampaignId : ""
                        }`}
                    >
                      <Button type="primary" className="btn-join">
                        {campaignData && campaignData.campaign.campaignJoin > 0
                          ? "Edit Stock"
                          : "Join"}
                      </Button>
                    </Link>
                  )}

                  {campaignData &&
                    campaignData.campaign.campaignStatus === "unblock" ? (
                    <>
                      {loadingCampaigndBtn ? (
                        <Button type="primary" className="btn-join mr-2" loading>
                          Block Campaign
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="btn-join mr-2"
                          onClick={() => handleCampaignStatus("block")}
                        >
                          Block Campaign
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {loadingCampaigndBtn ? (
                        <Button type="primary" className="btn-join mr-2" loading>
                          Unblock Campaign
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="btn-join mr-2"
                          onClick={() => handleCampaignStatus("unblock")}
                        >
                          Unblock Campaign
                        </Button>
                      )}
                    </>
                  )}
                  {campaignData &&
                    campaignData.campaign.brandStatus === "unblock" ? (
                    <>
                      {loadingBrandBtn ? (
                        <Button type="primary" className="btn-join mr-2" loading>
                          Block Brand
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="btn-join mr-2"
                          onClick={() => handleBrandStatus("block")}
                        >
                          Block Brand
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {loadingBrandBtn ? (
                        <Button type="primary" className="btn-join mr-2" loading>
                          Unblock Brand
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="btn-join mr-2"
                          onClick={() => handleBrandStatus("unblock")}
                        >
                          Unblock Brand
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Skeleton>
            </section>
            <section className="products">
              <h2>Products</h2>
              <Row gutter={[24, 24]} className="scrollbar">
                {loading ? (
                  <>
                    <Col xl={6} lg={8} xs={24}>
                      <div className="productLists">
                        <Skeleton paragraph={{ rows: 5 }} active>
                          {" "}
                        </Skeleton>
                      </div>
                    </Col>
                    <Col xl={6} lg={8} xs={24}>
                      <div className="productLists">
                        <Skeleton paragraph={{ rows: 5 }} active>
                          {" "}
                        </Skeleton>
                      </div>
                    </Col>
                    <Col xl={6} lg={8} xs={24}>
                      <div className="productLists">
                        <Skeleton paragraph={{ rows: 5 }} active>
                          {" "}
                        </Skeleton>
                      </div>
                    </Col>
                    <Col xl={6} lg={8} xs={24}>
                      <div className="productLists">
                        <Skeleton paragraph={{ rows: 5 }} active>
                          {" "}
                        </Skeleton>
                      </div>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {campaignData &&
                  campaignData.products.map((item, key) => {
                    return (
                      <Col xl={6} lg={8} xs={24} key={key}>
                        <div className="productLists">
                          <img
                            src={item.imageName}
                            alt="Icon"
                          />
                          <h4
                            className="pName text-ellipse1"
                            title={item.productCodeOveride}
                          >
                            {item.productCodeOveride}
                          </h4>
                          <p className="upcName text-ellipse1">{item.upc}</p>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </section>
          </section>
        </Col>
        <Col xl={6} lg={6} xs={24}>
          <section className="right">
            <div className="offSec">
              <h1>
                <Skeleton paragraph={{ rows: 0 }} loading={loading} active>
                  {`${getCampaignUserData().currencySign}${campaignData ? campaignData.campaign.Denomination : ""
                    }`}{" "}
                  Off
                </Skeleton>
              </h1>
              <div className="blank"></div>
            </div>
            <div className="noOfVoucher">
              <h4>No of Payment Codes</h4>
              <h1>
                <Skeleton paragraph={{ rows: 0 }} loading={loading} active>
                  {campaignData ? campaignData.campaign.CardCount : ""}
                </Skeleton>
              </h1>
            </div>
            <div className="totalOfferValue">
              <h4>Total Offer Value</h4>
              <h1>
                <Skeleton paragraph={{ rows: 0 }} loading={loading} active>{`${getCampaignUserData().currencySign
                  }${campaignData ? abbreviateNumber(campaignData.campaign.TotalOffer) : ""
                  }`}</Skeleton>
              </h1>
            </div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export { Details };
