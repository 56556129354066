import React,{useEffect} from "react";
import {
    Link
} from "react-router-dom";
import { Avatar, Tooltip } from "antd";
import { StarFilled, CheckCircleFilled } from "@ant-design/icons";
import Fade from 'react-reveal/Fade';
import Whatwegothere from './Whatwegothere'
import Banner1 from '../../assets/images/Frontend/brand.png'
import Empowerment from '../../assets/images/Frontend/Group 248831.png'
import Giftcards from '../../assets/images/Frontend/Group 248861.png'
import Laptop from '../../assets/images/Frontend/laptop mockup update new.png'
import Curvebottom from '../../assets/images/Frontend/curve bottom.svg'
import Curvetop from '../../assets/images/Frontend/curve top.svg'
import User1 from '../../assets/images/Frontend/customer01.png'
import User2 from '../../assets/images/Frontend/customer02.png'
import User3 from '../../assets/images/Frontend/customer03.png'
import YourGrowth from '../../assets/images/Frontend/your growth.png'
import BusinessGrowth from '../../assets/images/Frontend/business growth.png'

const BrandAndMarketing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <section id="banner" className="container-fluid banner-section d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 pt-5 ">
                            <Fade left><h1 className="pt-5 pb-2">KardStash powers your marketing, promotions and advertising</h1></Fade>
                            <div className='d-flex mb-5'>
                                <Fade left><Link to='/business' className='btn-app-store bg-fill mr-2'>Join now</Link></Fade>
                            </div>
                            <div className='d-flex mt-5'>
                                <Fade bottom><Avatar.Group size="large" className='me-4'>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User1} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User2} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User3} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                </Avatar.Group></Fade>
                                <div className='d-flex flex-column justify-content-center'>
                                    <Fade bottom><h5 className='mb-1'>Our Happy Customer</h5></Fade>
                                    <Fade bottom><p className='mb-0'><StarFilled style={{ fontSize: '1.2rem', color: '#FFC300' }} /> <strong style={{ fontSize: '1.2rem' }}>4.6 </strong>(10.15k Review)</p></Fade>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative py-2">
                            <Fade bottom><img src={Banner1} alt="icon" width="100%" /></Fade>
                            <Fade left><img src={YourGrowth} alt="icon" className="trust-icon"/></Fade>
                            <Fade left><img src={BusinessGrowth} alt="icon" className="b-icon"/></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="b-free" className="container-fluid b-free-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 ">
                            <Fade bottom><h2 className='text-center'><strong>Complete Marketing Solutions  </strong><br/>That Empowers Big Brands and Marketing <br/>Services Agencies</h2></Fade>
                        </div>
                    </div>
                    <div className='inner-box'>
                        <div className='row'>
                            <Fade bottom><div className="col-lg-10 offset-lg-1 col-12  text-center">
                                <img src={Empowerment} alt="icon" className='img-fluid my-4' />
                            </div></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="brand-solution" className="container-fluid brand-solution-section pb-0 spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 text-center">
                            <Fade bottom><h3 className="mb-0">KardStash powers smart, secure, <strong>Digital engagement experiences</strong> <br/>to help drive loyalty and incremental sales for your clients</h3></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="curve-top"> <img src={Curvetop} alt="icon"/></section>
            <section id="business-section1" className="container-fluid digital-section1   content">
                
                <div className="container align-self-center">
                    <div className='row pb-5 mb-5'>
                        <div className="col-lg-4 col-12 pt-5 pe-5">
                            <div className="content shadow p-4">
                                <Fade bottom><div className="checked-icon"><CheckCircleFilled /></div></Fade>
                                <Fade bottom><p className='mb-0'>Our platform enables brands of any size to execute unforgettable and compelling consumer engagement strategies across all touchpoints to meet unique retailer and shopper needs.</p></Fade>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 pt-5 pe-5">
                            <div className="content shadow p-4">
                                <Fade bottom><div className="checked-icon"><CheckCircleFilled /></div></Fade>
                                <Fade bottom><p className='mb-0'>Built for the digital economy, our enterprise tool-set allows your company to create frictionless, seamless consumer engagement experiences from digital offer issuance to redemption - building and tracking  shopper engagement and product sales.</p></Fade>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 pt-5 pe-5">
                            <div className="content shadow p-4">
                                <Fade bottom><div className="checked-icon"><CheckCircleFilled /></div></Fade>
                                <Fade bottom><p className='mb-0'>Our platform seamlessly digitizes your promotions and pushes it directly to merchants. KardStash gives Brands complete control to manage campaigns and promotions.</p></Fade>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-12 pt-5 px-5 text-center">
                            
                            <Fade bottom><h3 className="pb-4 px-5">KardStash empowers your team with the ability to build and manage your <strong>own offers for your clients without going through a 3rd Party.</strong></h3></Fade>
                        </div>
                        <div className="col-lg-12 col-12">
                            <Fade bottom><img src={Laptop} alt="icon" width="100%" className='img-fluid pe-5' /></Fade>
                        </div>
                    </div>
                </div>
               
            </section>
            <section className="curve-bottom"> <img src={Curvebottom} alt="icon"/></section>
            <section id="business-section2" className="container-fluid digital-section2 pb-5 content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={Giftcards} alt="icon" width="90%" className='img-fluid pe-5' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5 pe-5">
                            <Fade right><h4 className='heading ternary'>Solution</h4></Fade>
                            <Fade right><h2 className="mb-5">Giftcards &amp; Rebates </h2></Fade>
                            <Fade right><h4 className="mb-5">Data insight using the power of sale </h4></Fade>
                            <Fade right><p className='mb-4'>Imagine you have all the data insight to drive demand and actions in the market place using the power of point of sale</p></Fade>
                            <Fade right><p className='mb-4'>View Consumer Cart data, inventory, stock, customer behavioural data, Distribution data and customer geolocation data</p></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <Whatwegothere/>
        </>
    );
};
export default BrandAndMarketing;