const timeDiff = (date1) => {
  const timeStart = new Date(date1).getTime();
  const timeEnd = new Date().getTime();
  let diff = {};
  diff.milliseconds = timeEnd - timeStart;
  diff.seconds = diff.milliseconds / 1000;
  diff.minutes = diff.seconds / 60;
  diff.hours = diff.minutes / 60;
  diff.days = diff.hours / 24;
  diff.weeks = diff.days / 7;

  return diff.weeks >= 1
    ? `${parseInt(diff.weeks)} weeks`
    : diff.days >= 1
    ? `${parseInt(diff.days)} days`
    : diff.hours >= 1
    ? `${parseInt(diff.hours)} hours`
    : diff.minutes >= 1
    ? `${parseInt(diff.minutes)} min`
    : diff.seconds >= 1
    ? `${parseInt(diff.seconds)} sec`
    : `${parseInt(diff.milliseconds)} ms`;
};

export { timeDiff };
