import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import React, { useEffect } from "react";
import { EmptyComponents } from "../../common/components/";
import { AppConfig } from "../../config/AppConfig";
import dummyImage from '../../assets/images/templateNotFound.png';
import Ngicon from "../../assets/images/nigeria.png";
import Ukicon from "../../assets/images/uk.jpg";
import Usaicon from "../../assets/images/usa.png";

const Managestock = (props) => {
  const { campaign } = props;
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      <div
        className="bg-white dashboard-left-box"
        style={{ borderRadius: 4 }}
      >
        <div className="card-header">
          <div className="stock-box">
            <div>
              <small>Your Total Stock</small>
              <h2>{parseInt(campaign?.total) || 0}</h2>
            </div>
            <div className="country-icon">
              {campaign?.isoCode === "NGA" ? (
                <img src={Ngicon} width="50" height="35" alt="Icon" />
              ) : campaign?.isoCode === "UK" ? (
                <img src={Ukicon} width="50" height="35" alt="Icon" />
              ) : (
                <img src={Usaicon} width="50" height="35" alt="Icon" />
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <Skeleton paragraph={{ rows: 12 }} loading={loading} active>
            {campaign?.productData ? (campaign?.productData.map((productsStockData) => (
              <div className="products-stock-table" key={productsStockData.key}>
                <div className="product-icon">
                  <img src={
                    productsStockData.image
                      ? productsStockData.image.startsWith('https://')
                        ? productsStockData.image
                        : `${AppConfig.API_ENDPOINT}/${productsStockData.image}`
                      : dummyImage
                  }
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = dummyImage;
                    }} alt="Icon" />
                </div>
                <div className="product-name">
                  <h4>{productsStockData.code}</h4>
                </div>
                <div className="count">
                  <h4>
                    {productsStockData.stock >= 50 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )}{" "}
                    {productsStockData.stock}
                  </h4>
                </div>
              </div>
            ))) : (
              <EmptyComponents />
            )}
          </Skeleton>
        </div>
      </div>
    </>
  );
};
export default Managestock;
