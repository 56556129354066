import React, { useEffect, useState } from "react";
import FrontendHeader from "../../common/components/frontend/header";
import FrontendFooter from "../../common/components/frontend/footer";
import FrontendStasher from "../../modules/frontend/Stasher";
import { v4 as uuidv4 } from 'uuid';
import "../../modules/frontend/frontend.less";

const Stasher = () => {
  // const [macAddress, setMACaddress] = useState("");
  // useEffect(() => {
  //   setMACaddress(new DeviceUUID().get());
  // }, []);
  return (
    <div id="frontend">
      macAddress: {uuidv4()}
      <FrontendHeader />
      <main>
        <div className="page-body">
          <FrontendStasher />
        </div>
      </main>
      <FrontendFooter />
    </div>
  );
};
export default Stasher;
