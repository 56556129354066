import React from "react";
import { Layout, Row, Col, Input, Space, message, Card, Form,  Select } from "antd";
import { PageTitle } from "../../common/components/layout/";
import { ClipboardCopy } from "../../common/components/";
import "../../modules/campaigns/campaigns.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { AppConfig } from "../../config/AppConfig";
import { ApiHelper,getUserData } from "../../Helpers";
// import { Loader } from "../../common/components/";

const { Content } = Layout;
const { Option } = Select;
const title = "My Profile";
const Myprofile = () => {
  const userData=getUserData();
  console.log('userData',userData)
  // const [loader, setLoader] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values) => {
  //  setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_PASSWORD.service,
      ApiRoutes.CHANGE_PASSWORD.url,
      ApiRoutes.CHANGE_PASSWORD.method,
      ApiRoutes.CHANGE_PASSWORD.authenticate,
      undefined,
      values
    );
   // setIsLoading(false);
    if (response.code === 200) {
      message.error({
        content: response.messages[0],
        duration: 2,
      });
      message.success({
        content: response.messages[0],
        duration: 2,
      });
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    //  setLoader(false);
  };

  //Upload Code
  // const [fileList, setFileList] = useState([
  //   {
  //     uid: '-1',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  // ]);
  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };
  // const onPreview = async file => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise(resolve => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow.document.write(image.outerHTML);
  // };
  //Upload Code
  const primaryColor = <div style={{backgroundColor:userData.primaryColor, width:'28px', height: '28px'}}></div>;
  const secondaryColor = <div style={{backgroundColor:userData.secondaryColor, width:'28px', height: '28px'}}></div>;
  const tertiaryColor = <div style={{backgroundColor:userData.tertiaryColor, width:'28px', height: '28px'}}></div>;

  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200, margin: "0px 25px" }}
        id="Campaigns"
      >
        <Row>
          <Col lg={24} xs={24}>
            <div style={{ margin: "0px 14px 14px 14px" }}>
              <Row className="campaignAddBox" gutter="24">
                <Col xl={24} xs={24}>
                  <Form layout="vertical" onFinish={onFinish} validateTrigger='onSubmit'>
                    <div>
                      <Card className="campaignInformationCard profileSec"  >
                        <Row gutter="48">
                          <Col xl={12} xs={24}   className="mb-5">
                              <div className="d-flex align-item-center">
                                <div className="brandLogo mr-4">
                                  <img src={userData.brandImage} alt="Icon"/>
                                </div>
                                <div style={{width:'79%'}}>
                                  <label>Brand Name</label>
                                  <h2 className="text-ellipse1 mb-0">{userData.organizationName}</h2>
                                </div>
                              </div>
                          </Col>
                          <Col xl={12} xs={24}   className="mb-5 d-flex align-item-center">
                              <div className="copyBox">
                                <ClipboardCopy
                                  copyText={`${AppConfig.CAMPAIGN_URL.replace('campaign_slug', getUserData().webUrl)}`}
                                />
                              </div>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Username"
                              name="username"
                            >
                              <Input type="text" defaultValue={userData.userName} disabled/>
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Email"
                              name="email"
                            >
                              <Input type="email" defaultValue={userData.email} disabled/>
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Phone Number"
                              name="phoneNumber"
                            >
                             <Input addonBefore={`+ ${userData.phoneCode}` } defaultValue={userData.businessPhone} disabled />
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Country"
                              name="Country"
                            >
                              <Select defaultValue={userData.countryName}  disabled >
                                <Option value="USA">{userData.countryName}</Option>
                              </Select>
                            </Form.Item>
                          </Col>                      
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Primary Color"
                              name="primaryColor"
                            >
                              <Input  addonAfter={primaryColor} defaultValue={userData.primaryColor} disabled />
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Secondary Color"
                              name="secondaryColor"
                            >
                              <Input  addonAfter={secondaryColor} defaultValue={userData.secondaryColor} disabled />
                            </Form.Item>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Form.Item
                              label="Tertiary Color"
                              name="tertiaryColor"
                            >
                              <Input  addonAfter={tertiaryColor} defaultValue={userData.tertiaryColor} disabled />
                            </Form.Item>
                          </Col>                          
                          {/* <Col
                            xl={24}
                            xs={24}
                          >
                            {isLoading ? (
                              <Button type="primary"  loading htmlType="submit" style={{float:'right'}}>
                                Submit
                              </Button>
                            ) : (
                              <Button type="primary"  htmlType="submit" style={{float:'right'}}>
                                Submit
                              </Button>
                            )}
                          </Col> */}
                        </Row>

                      </Card>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Myprofile;
