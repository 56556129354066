import React from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";


const Productlist = ({ storeData, loading }) => {
  return (
    <>
      <div
        className="bg-white store-product-list-box"
        style={{ borderRadius: 4 }}
      >
        <div className="card-body">
          <Skeleton paragraph={{ rows: 12 }} loading={loading} active>
            {storeData && storeData.products.map((item, key) => (
              <div className="products-stock-table" key={key}>
                <div className="product-icon">
                  {item.imageurl === "" ? (
                    <span>NC</span>
                  ) : (
                    <img src={item.imageurl} alt="Icon" />
                  )}
                </div>
                <div className="product-name">
                  <h4>{item.code}</h4>
                  <small>
                    <strong>UPC :</strong> {item.upc}
                  </small>
                </div>
                <div className="count">
                  <h4>
                    {item.currentQty >= 50 ? (
                      <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )}{" "}
                    {item.currentQty}
                  </h4>
                </div>
              </div>
            ))}
          </Skeleton>
        </div>
      </div>
    </>
  );
};

export default Productlist;
