import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, message, Row, Col, Button } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import Barcode from "react-barcode";
import MobileDetect from "mobile-detect";
import { Loader } from "../../common/components/Loader";
import "./customer.css";

import "../../modules/public/public.less";
// const { Content } = Layout;
const SaveToPhone = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const [osType, setOsType] = useState("");

  useEffect(() => {
    let type = new MobileDetect(window.navigator.userAgent);
    setOsType(type.os());
    // getBrandDetails();
    fetch("https://ipapi.co/json")
      .then((res) => res.json())
      .then((json) => {
        getBrandDetails(json.ip);
      })
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async (ip) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode,
        type: "voucher",
        ip
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (
          response.data.campaign.Cstatus === "1" ||
          response.data.campaign.Cstatus === 1
        ) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  const handleSaveCoupon = async () => {
    alert();
  };

  const handleSaveCouponPDF = async () => {
    window.location.href = `${process.env.REACT_APP_DEV_URL}/store/campaign/customer-voucher-pdf?pinNumber=${campaignData.voucherData.pinNumber}`;
  }

  return (
    <div id="mobile_body">
      <Layout id="mobile_page">
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Row className="campaignDetailSec">
            <Link className="navbar-brand" to="/">
              <img
                src={campaignData?.campaign?.logo}
                alt="logo"
                className="klogo d-inline"
              />
            </Link>
            <Col xs={24} xl={24}>
              <div className="text-center">
                {/* <p>Expires: March 29, 2022</p> */}
                <p>
                  Mac Address:{" "}
                  {campaignData ? campaignData.voucherData.macAddress : ""}
                </p>
                <h2>
                  <Link to="#" onClick={handleSaveCoupon}>
                    <Button>
                      {osType === "iOS"
                        ? "Save to Apple Wallet"
                        : "Save to G Pay"}
                    </Button>
                  </Link>
                  <br />
                  <Link to="#" onClick={handleSaveCouponPDF}>
                    <Button>Save to PDF</Button>
                  </Link>
                  <br />
                </h2>
                <Barcode value={campaignData ? campaignData.voucherData.pinNumber : ""} /><br />
                <Link to={`/c/${campaignCode}/find-store`}>Find Store</Link><br />
              </div>
            </Col>
            <Col xs={24} xl={24} className="campaign-condition">
              <h3>Privacy Policy</h3>
              <p>{campaignData ? campaignData.campaign.privacyPolicy : ""}</p>
              <h3>Website to Brand</h3>
              <p>{campaignData ? campaignData.campaign.terms : ""}</p>
              <h3>Disclaimer</h3>
              <p>{campaignData ? campaignData.campaign.disclaimer : ""}</p>
            </Col>
          </Row>
        )}
      </Layout>
    </div>
  );
};
export default SaveToPhone;
