import React from "react";
import { Row, Col, Card, Popover, Skeleton } from "antd";
import { Link } from "react-router-dom";
import moment from 'moment';
import { abbreviateNumber } from "../../Helpers";


const CampaignList = ({ campaign, loading, brandData }) => {
    return (
        <Row gutter={[48, 48]}>
            {campaign && campaign.map((campaignData, key) => {
                let stateList = [...new Set(campaignData.stateList.split(','))];
                let otherState = [];
                return (
                    <Col xs={24} xl={12} key={key}>
                        <Link to={`/${campaignData.CampaignCode}`}>
                            <Card type="inner" title={campaignData.CampaignName} className="campaignGrid">
                                <Skeleton paragraph={{ rows: 5 }} loading={loading} active>
                                    <div>
                                        <Row gutter={16}>
                                            <Col xs={24} xl={16}>
                                                <div className="dateBox" style={{ borderLeftColor: brandData ? brandData.primary : '' }}>
                                                    <div className="startDate">
                                                        <p>Start Date</p>
                                                        <h3>{moment(campaignData.CStartDate).format('DD MMM, YYYY')}</h3>
                                                    </div>
                                                    <div className="endDate">
                                                        <p>End Date</p>
                                                        <h3>{moment(campaignData.CEndDate).format('DD MMM, YYYY')}</h3>
                                                    </div>
                                                </div>
                                                <div className="stateBox">
                                                    <h4>States</h4>
                                                    <p>
                                                        {stateList.map((state, key1) => {
                                                            // eslint-disable-next-line
                                                            if (key1 < 4) {
                                                                return (<span style={{ backgroundColor: '#F6F6F6' }}>{state}</span>)
                                                            }
                                                            else {
                                                                otherState.push(state);
                                                            }
                                                        })}
                                                        {otherState.length ?
                                                            <Popover content={otherState.map((item) => {
                                                                return (<p>{item}</p>);
                                                            })} title="States"><span type="primary" style={{ backgroundColor: '#F6F6F6', fontWeight: 500 }}>+{otherState.length} more</span></Popover>
                                                            : ''}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={24} xl={8}>
                                                <div className="countBox">
                                                    <h4>Number of Voucher</h4>
                                                    <h2 style={{ color: brandData ? brandData.secondary : '' }}>{campaignData.CardCount}</h2>
                                                </div>
                                                <div className="countBox">
                                                    <h4>Total offer value</h4>
                                                    <h2 style={{ color: brandData ? brandData.secondary : '' }}>{`${brandData ? brandData.currencySign : ''}${abbreviateNumber(campaignData.TotalOffer)}`}</h2>
                                                </div>
                                                <div className="offBox" style={{ backgroundColor: brandData ? brandData.primary : '' }}>
                                                    <h2>{`${brandData ? brandData.currencySign : ''}${campaignData.Denomination}`}</h2>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Skeleton>
                            </Card>
                        </Link>
                    </Col>
                )
            }
            )}
        </Row>
    )
}

export { CampaignList };