import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Layout, message } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { CampaignDetail } from "../../modules/public/";
import "../../modules/public/public.less";

const { Content } = Layout;
const Detail = () => {
  const { campaignId } = useParams();
  const brandData = getCampaignUserData();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignId
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if(response.data.campaign.Cstatus==="1" || response.data.campaign.Cstatus===1)
        {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        }else{
          setCampaignData(response.data);
        }
        
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  return (
    <>
      <Layout>
        <Content
          style={{ minHeight: 200, padding: "80px", background: "#fff" }}
        >
          <CampaignDetail
            loading={loading}
            campaignData={campaignData}
            campaignId={campaignId}
            brandData={brandData}
          />
        </Content>
      </Layout>
    </>
  );
};
export default Detail;
