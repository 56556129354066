import React, { useEffect } from "react";
import { Button, Input, Form } from "antd";
import { EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import Usa from "../../assets/images/Frontend/usamap.png";
import Ng from "../../assets/images/Frontend/ng.png";

const BrandContact = () => {
  // const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section id="banner" className="container-fluid contact-banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center pb-5">
              <Fade bottom>
                <h1 className="mb-0">Contact Us</h1>
              </Fade>
              <Fade bottom>
                <h5 className="pb-2">
                  For any questions or to Book a Demo, please contact us, we are
                  always happy to help.
                </h5>
              </Fade>
            </div>
          </div>
          <div className="col-lg-12 contact-section">
            <div className="row">
              <div className="col-lg-6">
                <Fade bottom>
                  <h2 className="mb-5 pb-3">
                    <strong>Contact Information</strong>
                  </h2>
                </Fade>
                <div className="col-lg-12 pe-5">
                  <Fade left>
                    <div className="address-section  mb-5">
                      <div className="d-flex justify-content-between mb-5">
                        <h4>USA</h4>
                        <img src={Usa} alt="icon" width="50" />
                      </div>
                      <div className="d-flex">
                        <p className="mr-3">
                          <EnvironmentOutlined />
                        </p>
                        <div>
                          <p className="mb-0">
                            <strong>Address</strong>
                          </p>
                          <p>1231 Lyons Road Building E, Dayton Ohio 45458</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <p className="mr-3">
                          <PhoneOutlined />
                        </p>
                        <div>
                          <p>
                            <strong>937-886-7995</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade left>
                    <div className="address-section  mt-5">
                      <div className="d-flex justify-content-between mb-5">
                        <h4>NIGERIA</h4>
                        <img src={Ng} alt="icon" width="50" />
                      </div>
                      <div className="d-flex">
                        <p className="mr-3">
                          <EnvironmentOutlined />
                        </p>
                        <div>
                          <p className="mb-0">
                            <strong>Address</strong>
                          </p>
                          <p>
                            14A, Bayo Dejonwo street, Maryland, Lagos 100211
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <p className="mr-3">
                          <PhoneOutlined />
                        </p>
                        <div>
                          <p>
                            <strong>+234-902-130-9729</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
              <Fade right>
                <div className="col-lg-6 shadow br-10 p-5">
                  <Form layout="vertical" className="contact-form row mt-3">
                    <div className="col-lg-6 col-12">
                      <Form.Item
                        label="First name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name"
                          }
                        ]}
                      >
                        <Input placeholder="Enter first name" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6 col-12">
                      <Form.Item
                        label="Last name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name"
                          }
                        ]}
                      >
                        <Input placeholder="Enter last name" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-12">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username/Email!"
                          }
                        ]}
                      >
                        <Input placeholder="Enter email" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-12">
                      <Form.Item
                        label="Phone number"
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number"
                          }
                        ]}
                      >
                        <Input placeholder="Enter phone number" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-12 col-12">
                      <Form.Item label="Message" name="message">
                        <Input.TextArea placeholder="Enter message" />
                      </Form.Item>
                    </div>
                    <Form.Item className="btn-section mb-3">
                      <Button type="primary" htmlType="submit">
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5">
        <Fade bottom>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3073.0178611594583!2d-84.19707718462797!3d39.62680167946471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88408928c0670723%3A0x728cc8fc02e1c27d!2s1231%20Lyons%20Rd%2C%20Dayton%2C%20OH%2045458%2C%20USA!5e0!3m2!1sen!2sin!4v1645982210187!5m2!1sen!2sin"
            width="100%"
            height="450"
            title="google"
          ></iframe>
        </Fade>
      </section>
    </>
  );
};
export default BrandContact;
