import React, { useState, useEffect } from "react";
import { List } from "../../../modules/join/List";
import { message } from "antd";
import "../../../modules/join/join.less";
import InfiniteScroll from "react-infinite-scroller";

import { ApiRoutes } from "../../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../../Helpers";
import { limit } from "../../../config/AppConfig";
import { Loader } from "../../../common/components/Loader";
 
const JoinCampaigns = () => {
  const [offset, setOffset] = useState(0); 
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [brandData, setBrandData] = useState([]);

  const [campaignFilter, setCampaignFilter] = useState({
    limit: limit,
    search: "",
    stateId: getCampaignUserData().state,
    organizationId: getCampaignUserData().organizationId,
    blockStatus: "unblock"
  });
  useEffect(() => {
    getBrandList();
    const div=document.getElementById('wrapper');
    if(div && !div.classList.contains("overflow-hidden")){
      div.classList.add('overflow-hidden')
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    console.log("==== use effect ===");
    getCampaignList(offset);
    // eslint-disable-next-line
  }, [campaignFilter]);

  const getCampaignList = async (page = offset, filter = true) => {
    console.log("getCampaignList", hasMore);

    if (!hasMore) return false;
    setHasMore(false);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_LIST.service,
      ApiRoutes.STORE_CAMPAIGN_LIST.url,
      ApiRoutes.STORE_CAMPAIGN_LIST.method,
      ApiRoutes.STORE_CAMPAIGN_LIST.authenticate,
      { ...campaignFilter, offset: page + 1 }
    );

    if (response.code === 200) {
      setOffset(page);

      setTimeout(() => {
        console.log("====limit===", response.data.data.length, limit);
        setHasMore(response.data.data.length < limit ? false : true);
      }, 200);
      if (response.data.data.length > 0) {
        if (response.data.data.length < limit) setHasMore(false);
        if (campaign.length > 0 && filter) {
          setCampaign([...campaign, ...response.data.data]);
        } else setCampaign(response.data.data);
      } else setHasMore(false);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      setHasMore(false);
      setCampaign([]);
    }
    setLoading(false);
  };

  const getBrandList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_BRAND_LIST.service,
      ApiRoutes.CAMPAIGN_BRAND_LIST.url,
      ApiRoutes.CAMPAIGN_BRAND_LIST.method,
      ApiRoutes.CAMPAIGN_BRAND_LIST.authenticate,
      { stateId: getCampaignUserData().state }
    );
    if (response.code === 200) {
      setBrandData(response.data.brandList);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };

  const handleSearch = (e, type = "") => {
    setOffset(0);
    if (type === "status") {
      setCampaignFilter({ ...campaignFilter, blockStatus: e });
    }
    if (type === "brand") {
      setCampaignFilter({
        ...campaignFilter,
        brandOrganizationId: e.join(",")
      });
    }
    if (type === "") {
      const value = e.target.value;
      setCampaignFilter({ ...campaignFilter, search: value });
    }
    setHasMore(true);
    setCampaign([]);
  };

  return (
    <>
      {loading ? (
        <section
          className="text-center"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </section>
      ) : (
        <div id="Join" className="scrollbar" style={{ height: "80vh", overflowY: "auto", overflowX:"hidden" }} >
          <InfiniteScroll
            pageStart={offset}
            loadMore={getCampaignList}
            hasMore={hasMore}
            useWindow={false}
            loader={
              <section
                className="text-center"
                style={{
                  height: "80vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}

              >
                <Loader />
              </section>
            }
          >
            <List
              campaign={campaign}
              handleSearch={handleSearch}
              brandData={brandData}
            />{" "}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
export default JoinCampaigns;
