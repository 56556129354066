import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Row, Col, Button, Typography, Slider, Modal } from "antd";
import getCroppedImg from "./createImage";
import './styles.css';
const dogImg =
  "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";
// const classes=styles;

const ImageCropper = (props) => {
  const { isModalVisible, setIsModalVisible, cropImage,setImage } = props;
  const [crop, setCrop] = useState({ x: 10, y: 10 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
 

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        cropImage,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", croppedImage);
      setImage({
        preview: croppedImage.preview,
        raw: croppedImage.image || "",
        originImage:cropImage
      })
      setIsModalVisible(false);
     // setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

 
  console.log("cropImage",cropImage);
  return (
    <Modal
      title="Basic Modal"
      visible={isModalVisible}
      onOk={showCroppedImage}
      onCancel={handleCancel}
    >
      <Row>
        <Col className="crop-container">
          
          <Cropper
            image={cropImage}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Col>
          <Col xl={24} xs={24}>
            <Typography variant="overline">Zoom</Typography>

            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(zoom) => setZoom(zoom)}
            />
          </Col>
          <Col xl={24} xs={24}>
            <Typography variant="overline">Rotation</Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(rotation) => setRotation(rotation)}
            />
          </Col>
          {/* <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          Show Result
        </Button> */}
      </Row>
    </Modal>
  );
};
export default ImageCropper;
