import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Input, Card, DatePicker, InputNumber } from "antd";
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { abbreviateNumber } from "../../Helpers";

const CampaignInformationCard = (props) => {
  const { id } = useParams();
  const { currency } = props;
  const [totalOfferValue, setTotalOfferValue] = React.useState(
    props?.data?.totalOfferValue || 0
  );
  const [denomination, setDenomination] = React.useState(
    props?.data?.denomination
  );
  const [noOfVoucher, setNoOfVoucher] = React.useState(
    props?.data?.noOfVoucher
  );
  const changeValue = (e, key) => {
    if (key === "denomination" || key === "noOfVoucher") {
      let value = 0;

      if (key === "denomination") {
        setDenomination(e.target.value);
        value = parseInt(noOfVoucher || 0) * (e.target.value || 0);
        props.setFormData({ totalOfferValue: value, [key]: e.target.value });
      }
      if (key === "noOfVoucher") {
        setNoOfVoucher(e.target.value);
        value = (denomination || 0) * parseInt(e.target.value || 0);
        props.setFormData({
          totalOfferValue: value,
          [key]: parseInt(e.target.value)
        });
      }
      setTotalOfferValue(value > 0 ? value.toFixed(2) : value);
    } else {
      props.setFormData({ [key]: e.target.value });
    }
  };
  const onStartDate = (__date, dateString) => {
    props.setFormData({ startDate: dateString });
  };
  const onEndDate = (__date, dateString) => {
    props.setFormData({ endDate: dateString });
  };
  return (
    <>
      <Card className="campaignInformationCard showWhenChecked" hoverable>
        <Row gutter="24">
          <Col xl={8} xs={24}>
            <Form.Item
              label="Denomination"
              name="denomination"
              className="denominationText"
              id="denomination"
              initialValue={props?.data?.denomination}
              onKeyUp={(e) => changeValue(e, "denomination")}
              rules={[
                {
                  required: false,
                  message: "Please input your Denomination"
                },
                {
                  required: true,
                  pattern: /^[0-9.]+$/,
                  message: "No. of denomination has to be a number."
                }
              ]}
            >
              <InputNumber
                type="text"
                prefix={currency}
                style={{ width: "100%" }}
                maxlength="9"
                pattern="\d*" 
              />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="Number of Voucher(s)"
              name="noOfVoucher"
              className="noOfVoucherText"
              initialValue={props?.data?.noOfVoucher}
              onKeyUp={(e) => changeValue(e, "noOfVoucher")}
              rules={[
                {
                  required: false,
                  message: "Please input your No. of Voucher"
                },
                () => ({
                  async validator(_, value) {
                    console.log(value, value.length);
                    if (value.length > 8) {
                      return Promise.reject(
                        "Value should be less than 8 character."
                      );
                    }
                    return Promise.resolve();
                  }
                }),
                {
                  required: true,
                  pattern: /^[0-9]+$/,
                  message: "No. of Voucher has to be a number."
                }
              ]}
            >
              <InputNumber type="text" style={{ width: "100%" }} pattern="\d*" maxlength="9" />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <div className="offerValueBox">
              <p>Total Offer Value</p>
              <h4>
                {currency}
                {abbreviateNumber(totalOfferValue)}
              </h4>
            </div>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="Campaign Name"
              name="campaignName"
              initialValue={props?.data?.campaignName}
              onChange={(e) => changeValue(e, "campaignName")}
              rules={[
                {
                  required: true,
                  message: "Please input your Campaign Name"
                }
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="Start Date"
              name="startDate"
              initialValue={
                props?.data?.startDate ? moment(props?.data?.startDate) : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input your Start Date"
                }
              ]}
            >
              <DatePicker
                onChange={onStartDate}
                disabledDate={(d) =>
                  d.isSameOrBefore(moment().format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="End Date"
              name="endDate"
              initialValue={
                props?.data?.endDate ? moment(props?.data?.endDate) : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input your End Date"
                }
              ]}
            >
              <DatePicker
                onChange={onEndDate}
                disabledDate={(d) => {
                  if (id === undefined) {
                    return d.isSameOrBefore(
                      moment(
                        props?.data?.startDate ? props?.data?.startDate : ""
                      ).format("YYYY-MM-DD")
                    );
                  } else {
                    return d.isSameOrBefore(
                      moment(
                        props?.data?.startDate ? props?.data?.startDate : ""
                      )
                        .subtract(1, "d")
                        .format("YYYY-MM-DD")
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            {/* <button className="btnNext ant-btn ant-btn-primary">Next <RightOutlined /></button> */}
            <label
              htmlFor="step-1"
              onClick="onclickNext('step-1')"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"
            >
              Next <RightOutlined />
            </label>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { CampaignInformationCard };
