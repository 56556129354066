import { Layout, Row, Space, Col, message } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Search, PageTitle } from "../../common/components/layout/";
import { PlusOutlined } from "@ant-design/icons";
import { CampaignList } from "../../modules/campaigns";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { limit } from "../../config/AppConfig";
import { Loader, EmptyComponents } from "../../common/components/";
import "../../modules/campaigns/campaigns.less";

const { Content } = Layout;
const title = "Campaigns";
const DetailsCampaigns = () => {
  const [offset, setOffset] = useState(0);
  const [campaign, setCampaign] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearch] = useState("");
  useEffect(() => {
    getCampaignList(offset);
    // eslint-disable-next-line
  }, []);

  const getCampaignList = async (
    page = offset,
    search = searchWord,
    filter = true
  ) => {
    setLoading(true);
    //setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_LIST.service,
      ApiRoutes.CAMPAIGN_LIST.url,
      ApiRoutes.CAMPAIGN_LIST.method,
      ApiRoutes.CAMPAIGN_LIST.authenticate,
      {
        limit: limit,
        offset: page + 1,
        search: search,
      }
    );
    setOffset(page + 1);
    if (response.code === 200) {
      if (response.data.data.length > 0) {
        if (campaign.length > 0 && filter) {
          setCampaign([...campaign, ...response.data.data]);
        } else setCampaign(response.data.data);
      } else {
      
        if (filter === false) setCampaign(response.data.data);
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    setLoading(false);
    setLoader(false);
  };
  //   const params = props.match.params;
  const filterCampaign = (e) => {
    getCampaignList(0, e.target.value, false);
    // setOffset(0);
    setSearch(e.target.value);
    setLoader(true);
    setCampaign([]);
  };
  return (
    <>
      <Space className="filter-sec headerFix">
        <PageTitle title={title} />
        <Search action={(e) => filterCampaign(e)} />
        <Link to="/campaign/add" className="btn-primary">
          {" "}
          <PlusOutlined /> Create Campaigns
        </Link>
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200 }}
        id="Campaigns"
      >
        <Row>
          <Col lg={24} xs={24}>
            <div
              style={{
                margin: "0px 40px 14px 40px",
                // height: "700px",
              }}
            >
              {loader ? (
                <section className="text-center" style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Loader /></section>
              ) : campaign.length > 0 ? (
                <div>
                  <CampaignList data={campaign} loading={loading} getCampaignList={getCampaignList} />
                </div>
              ) : (
                <EmptyComponents
                  title="Campaign"
                  message="No Campaign Available "
                />
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default DetailsCampaigns;
