import { Layout, Row, Col, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../common/components/layout/";
import { Loader } from "../../../common/components/";
import { CountryGraph, Stores } from "../../../modules/dashboard/";
import "../../../modules/dashboard/dashboard.less";
import { ApiRoutes } from "../../../config/ApiRoutes";
import { ApiHelper,getUserData } from "../../../Helpers";

const { Content } = Layout;
const title = "Dashboard";
const Dashboard = (props) => {
  const query = new URLSearchParams(props.match.params);
  const campaignId = query.get("id");
  const [campaign, setCampaign] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [store, setStore] = useState([]);
  const [heatMap, setHeatMap] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [state, setState] = useState([]);
  const [storeFilter, setStoreFilter] = useState({ campaignId, stateId: '', search: '' });
  // const params = props.match.params;
  const [loader, setLoader] = useState(true);
  const country = getUserData().currency;
  useEffect(() => {
    getCampaignDetail();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getStore();
    // eslint-disable-next-line
  }, [storeFilter]);
  const getCampaignDetail = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.service,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.url,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.method,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.authenticate,
      { campaignId: campaignId }
    );
    const responseState = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_FETCH_STATE.service,
      ApiRoutes.CAMPAIGN_FETCH_STATE.url,
      ApiRoutes.CAMPAIGN_FETCH_STATE.method,
      ApiRoutes.CAMPAIGN_FETCH_STATE.authenticate
    );
    setLoader(false);
    if (response.code === 200) {
      setCampaign(response.data.data);
      setCampaignData(response.data.campaign);
      // setHeatMap([
      //   {
      //     "MAIL_ST_PROV_C": "BY",
      //   },
      //   {
      //     "MAIL_ST_PROV_C": "BY",
      //   },
      //   {
      //     "MAIL_ST_PROV_C": "BY",
      //   },
      // ]);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    if (responseState.code === 200) {
      if (responseState.data?.states && responseState.data?.states.length > 0)
        setState(responseState.data.states);
    }
  };
  const getStore = async () => {
    setIsLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_JOIN_STORE.service,
      ApiRoutes.CAMPAIGN_JOIN_STORE.url,
      ApiRoutes.CAMPAIGN_JOIN_STORE.method,
      ApiRoutes.CAMPAIGN_JOIN_STORE.authenticate,
      storeFilter
    );
    setIsLoader(false);
    if (response.code === 200) {
      setStore(response.data.campaignData);
      let graphData = [];
      for (let x in response.data.campaignData) {
        if (response.data.campaignData[x].code) {
          graphData.push({
            "co_loc_n": response.data.campaignData[x].address,
            "store_name": response.data.campaignData[x].organizationName,
            "MAIL_ST_PROV_C": response.data.campaignData[x].code,
            "LNGTD_I": response.data.campaignData[x].longitude,
            "LATTD_I": response.data.campaignData[x].latitude,
            "mail_city_n": response.data.campaignData[x].address,
            "count": response.data.campaignData[x].totalStock,
          });
        }
      }
      setHeatMap(graphData);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
  };
  const handleStoreFilter = (e) => {
    if (e.target) {
      setStoreFilter({ ...storeFilter, search: e.target.value });
    }
    else {
      setStoreFilter({ ...storeFilter, stateId: e });
    }
  }
  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200 }}
        id="Dashboard"
      >
        <Row>
          <Col lg={24} xs={24}>
            {loader ? (
              <section className="text-center" style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></section>
            ) : (
              <div
                style={{
                  margin: "0px 40px 14px 40px"
                }}
              >
                <CountryGraph country={country} campaign={campaign} heatMap={heatMap} loader={loader} campaignData={campaignData} />
                <Stores store={store} state={state} handleStoreFilter={handleStoreFilter} isLoader={isLoader} campaignId={campaignId}/>
              </div>)}
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Dashboard;
