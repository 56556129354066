import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Form, Row, Col, Button, notification } from "antd";
import "../../modules/public/public.less";
import MonoConnect from "@mono.co/connect.js";
import Otpsuccess from '../../assets/images/otpVerified.png'
import { usePlaidLink } from "react-plaid-link";
import { getCampaignUserData } from "../../Helpers";
import { AppConfig } from "../../config/AppConfig";
import { Loader } from "../../common/components/Loader";

const { Content } = Layout;

const VerifyAccount = () => {
  const history = useHistory();
  const search = useLocation().search;
  const brandData = getCampaignUserData();
  const queryString = new URLSearchParams(search);
  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    generateToken();
    // eslint-disable-next-line
  }, []);

  const generateToken = async () => {
    const response = await fetch(
      `${AppConfig.API_ENDPOINT}/plaid/auth/create-link-token?employeeId=1&userName=test`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    setLinkToken(data.linkToken);
  };

  const Link = (props) => {
    const onSuccess = React.useCallback(async (public_token, metadata) => {
      // send public_token to server
      setLoading(true);
      const response = await fetch(
        `${AppConfig.API_ENDPOINT}/plaid/auth/token-exchange`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            publicToken: public_token,
            organizationId: queryString.get("organizationId"),
            employeeId: "",
            countryId: brandData.country
          })
        }
      );
      if (response.status === 200) {
        notification.success({
          message: 'Account verified successfully',
        });
        history.push(
          `/public/store?campaignId=${queryString.get(
            "campaignId"
          )}&organizationId=${queryString.get(
            "organizationId"
          )}&merchantStoreType=${queryString.get("merchantStoreType")}`
        );
      }
      // Handle response ...
    }, []);

    const config = {
      token: props.linkToken,
      // receivedRedirectUri: window.location.href,
      onSuccess
    };
    const { open, ready } = usePlaidLink(config);
    return (
      <Button
        onClick={() => open()}
        disabled={!ready}
        block
        className="mb-4"
        style={{
          backgroundColor: brandData ? brandData.primary : "",
          color: "#fff",
          borderColor: "#FB984A"
        }}
      >
        Link Account
      </Button>
    );
  };

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => console.log("Widget closed"),
      onLoad: () => console.log("Widget loaded successfully"),
      onSuccess: async ({ code }) => {
        setLoading(true);
        const response = await fetch(
          `${AppConfig.API_ENDPOINT}/plaid/auth/check-identity`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              code: code,
              organizationId: queryString.get("organizationId"),
              employeeId: "",
              countryId: brandData.country
            })
          }
        );
        if (response.status === 200) {
          notification.success({
            message: 'Account verified successfully',
          });
          history.push(
            `/public/store?campaignId=${queryString.get(
              "campaignId"
            )}&organizationId=${queryString.get(
              "organizationId"
            )}&merchantStoreType=${queryString.get("merchantStoreType")}`
          );
        }
      },
      key: process.env.REACT_APP_MONO_CONNECT_KEY
    });
    monoInstance.setup();
    return monoInstance;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <section
          className="text-center"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </section>
      ) : (
        <Layout>
          <Content
            style={{ minHeight: 200, padding: "40px 80px 80px 80px", background: "#fff" }}
          >
            <Row gutter="24">
              <Col xl={12} xs={24} offset={6} align="center" className="mb-5">
                  <img src={Otpsuccess} alt="icon" width="40%"/>
              </Col>
              <Col xl={12} xs={24} offset={6} className="otpSec">
                <Row gutter={24}>
                  <Col xs={24} xl={24}>
                    <h2>Please verify your account</h2>
                    <Form.Item className="btnBox mb-0">
                      {brandData && brandData.country === 1 ? (
                        <>
                          {linkToken != null ? (
                            <Link linkToken={linkToken} />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => monoConnect.open()}
                            block
                            className="mb-4"
                            style={{
                              backgroundColor: brandData ? brandData.primary : "",
                              color: "#fff",
                              borderColor: "#FB984A"
                            }}
                          >
                            Link Account
                          </Button>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    </>
  );
};
export default VerifyAccount;
