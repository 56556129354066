import React, { useEffect, useState } from "react";
import { Layout as Layouts } from "antd";
import Footerlayout from "./footer/index";
import { Loader } from "../components/Loader";
import dummyImage from "../../assets/images/templateNotFound.png";
import { ApiRoutes } from "../../config/ApiRoutes";
import { AppConfig } from "../../config/AppConfig";
import {
  ApiHelper,
  // getCampaignUserData,
  setCampaignUserData
} from "../../Helpers";
const { Header } = Layouts;

const PublicLayout = (props) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [brandData, setBrandData] = useState(null);
  useEffect(() => {
    console.log()
    let host = window.location.host;
    let parts = host.split(".");
    let subdomain = "";
    if (parts.length >= 6 || subdomain !== '') {
      subdomain = parts[0];
      console.log('subdomain', subdomain)
      // Remove the subdomain from the parts list
      parts.splice(0, 1);
      // if(getCampaignUserData().subdomain===subdomain){
      //   setPageLoading(false);
      //   setBrandData(getCampaignUserData());
      // }else
      getBrandDetails(subdomain);
      // Set the location to the new url
    } else {
      //window.location.href = process.env.REACT_APP_LOCAL_URL;
    }
    // eslint-disable-next-line
  }, []);
  const getBrandDetails = async (subdomain) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BRAND_DETAILS.service,
      ApiRoutes.BRAND_DETAILS.url,
      ApiRoutes.BRAND_DETAILS.method,
      ApiRoutes.BRAND_DETAILS.authenticate,
      { webUrl: subdomain }
    );
    if (response.code === 200) {
      setPageLoading(false);
      response.data.data.subdomain = subdomain;
      setCampaignUserData(response.data.data);
      setBrandData(response.data.data);
    } else {
      console.log('get brand')
      //window.location.href = process.env.REACT_APP_LOCAL_URL;
    }
  };
  return (
    <>
      <div className="ekiakrtApp" id="kardstashLayout">
        {pageLoading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Layouts style={{ minHeight: "100vh" }}>
            <Layouts className="full-layout" id="Public">
              <Header>
                <img
                  src={
                    brandData && brandData.imageName
                      ? brandData.imageName.startsWith("https://")
                        ? brandData.imageName
                        : `${AppConfig.API_ENDPOINT}/${brandData.imageName}`
                      : dummyImage
                  }
                  style={{width:"100%"}}
                  alt="logo"
                />
              </Header>
              <>{props.children}</>
              <Footerlayout />
            </Layouts>
          </Layouts>
        )}
      </div>
    </>
  );
};

export default PublicLayout;
