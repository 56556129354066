import React, { useEffect, useState, useRef } from "react";
import { Layout, Row, Col, Tooltip, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CampaignAdd } from "../../modules/campaigns/";
import "../../modules/campaigns/campaigns.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { AppConfig } from "../../config/AppConfig";
import { ApiHelper, getCurrency, getUserData } from "../../Helpers";
import { Loader, ClipboardCopy } from "../../common/components/";
import { triggerBase64Download } from "react-base64-downloader";
import QRImage from "react-qr-image";

const { Content } = Layout;

const AddCampaigns = () => {
  const Currency = getCurrency();
  const imageRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [campaignCode, setCampaignCode] = useState("");
  const [state, setState] = useState([]);
  const [policyData, setPolicyData] = useState({});

  const [products, setProducts] = useState([]);
  const getCampaignCode = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_CODE.service,
      ApiRoutes.CAMPAIGN_CODE.url,
      ApiRoutes.CAMPAIGN_CODE.method,
      ApiRoutes.CAMPAIGN_CODE.authenticate
    );
    if (response.code === 200) {
      if (response.data.code !== "") {
        setCampaignCode(response.data.code);
      } else {
        message.error({
          content: response.messages || response.error,
          duration: 2
        });
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    setLoader(false);
  };
  const fetchData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_FETCH_DATA.service,
      ApiRoutes.CAMPAIGN_FETCH_DATA.url,
      ApiRoutes.CAMPAIGN_FETCH_DATA.method,
      ApiRoutes.CAMPAIGN_FETCH_DATA.authenticate
    );
    if (response.code === 200) {
      if (response.data?.states && response.data?.states.length > 0)
        setState(response.data.states);
      if (response.data?.products && response.data?.products.length > 0)
        setProducts(response.data.products);
      if (response.data.policyData) setPolicyData(response.data.policyData);
    }
  };
  useEffect(() => {
    getCampaignCode();
    fetchData();
  }, []);
  return (
    <>
      {loader ? (
        <section
          className="text-center"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </section>
      ) : (
        <>
          <section id="Campaigns" className="headerFix">
            <div className="campaignHeader">
              <span className="codeBox">Campaign Code #{campaignCode}</span>
              <div className="copyBox">
                <ClipboardCopy
                  copyText={`${AppConfig.CAMPAIGN_URL.replace(
                    "campaign_slug",
                    getUserData().webUrl
                  )}/${campaignCode}`}
                />
              </div>
              <div
                className="downloadBox"
                onClick={() =>
                  triggerBase64Download(
                    imageRef.current.getElementsByTagName("img")[0].src,
                    "QRCode"
                  )
                }
              >
                <Tooltip title="Download">
                  {" "}
                  <DownloadOutlined />
                </Tooltip>
              </div>
              <div
                className="qrBox"
                ref={imageRef}
                onClick={() =>
                  triggerBase64Download(
                    imageRef.current.getElementsByTagName("img")[0].src,
                    "QRCode"
                  )
                }
              >
                <Tooltip title="Qr Code">
                  {" "}
                  <QRImage text={`${AppConfig.API_ENDPOINT}/${campaignCode}`} />
                </Tooltip>
              </div>
            </div>
          </section>
          <Content
            className="content-body"
            style={{ minHeight: 200, margin: "0px 25px" }}
            id="Campaigns"
          >
            <Row>
              <Col lg={24} xs={24}>
                <div style={{ margin: "0px 14px 14px 14px" }}>
                  <CampaignAdd
                    state={state}
                    products={products}
                    campaignCode={campaignCode}
                    currency={Currency}
                    policyData={policyData}
                    setLoader={setLoader}
                    brandLogo={getUserData().brandImage}
                  />
                </div>
              </Col>
            </Row>
          </Content>
        </>
      )}
    </>
  );
};
export default AddCampaigns;
