import { CloudUploadOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, Input, Upload, Button, Tooltip, message } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useState, useEffect } from "react";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { Loader } from "../../common/components/Loader";

const ProductEdit = (props) => {
  const [imageError, setImageError] = useState(false);
  const [productImage, setProductImage] = useState(props.productImage);
  const [productName, setProductName] = useState(props.productName);
  const [productUPC, setProductUPC] = useState(props.productUPC);

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setProductUPC(props.productUPC);
    setProductImage(props.productImage);
    setProductName(props.productName);
  }, [props.productUPC, props.productImage, props.productName]);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: productImage,
      status: "done",
      url: productImage,
    },
  ]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length === 0) setProductImage("");
    setImageError(false);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dragDropText = () => {
    if (fileList.length < 1) {
      return (
        <div className="dragDropText">
          <CloudUploadOutlined />
          <p>Drag and Drop Here</p>
        </div>
      );
    }
  };
  const onFinish = async (values) => {
    if (fileList[0]?.thumbUrl === undefined && productImage === "") {
      setImageError(true);
      return false;
    }
    setImageError(false);
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCT_EDIT.service,
      ApiRoutes.PRODUCT_EDIT.url,
      ApiRoutes.PRODUCT_EDIT.method,
      ApiRoutes.PRODUCT_EDIT.authenticate,
      undefined,
      {
        name: values.productName,
        upc: values.productUPC,
        productImage: fileList[0]?.thumbUrl,
        organizationProductId: props.organizationProductId,
      }
    );
    if (response.code === 200 && response.data.success === 1) {
      message.success({
        content: response.data.message,
        duration: 2,
      });
      props.showDrawer("");
      props.productInfoUpdate(response.data.data, props.index);
      // history.push({
      //   pathname: `/products`,
      // });
      ///setProduct(response.data.data);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    setLoader(false);
  };

  return (
    <>
      {loader ? <section className="text-center" style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Loader /></section> : ""}
      <Form
        layout="vertical"
        name="productEdit"
        autoComplete="off"
        initialValues={{
          productName,
          productUPC,
        }}
        onFinish={onFinish}
      >
        <ImgCrop rotate>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {dragDropText()}
          </Upload>
        </ImgCrop>
        {imageError ? (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">Please select your Product Image</div>
          </div>
        ) : (
          ""
        )}
        <Form.Item
          label="Product Name"
          name="productName"
          rules={[
            {
              required: true,
              message: "Please input your Product Name",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Product UPC"
          name="productUPC"
          rules={[
            {
              required: true,
              message: "Please input your Product UPC",
            },
            { min: 11, message: 'Value should accept at least 11 digit number.' },
            { max: 14, message: 'Value should be less than 14 character.' },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item align="right">
          <Tooltip title="Save">
            <Button
              type="primary"
              shape="circle"
              htmlType="submit"
              icon={<SaveOutlined />}
            ></Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </>
  );
};

export { ProductEdit };
