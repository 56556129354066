import React from "react";
import { Row, Col, Form, Input, Select } from "antd";
import MapContainer from "../../common/components/MapContainer";
import { Empty } from "antd";
const { Option } = Select;
const Storeinformation = ({
  storeForm,
  stateData,
  cityData,
  handleStateChange,
  setLatitude,
  setLongitude,
  initialLocation,
  handleCityChange,
  campaignSelectState
}) => {
  return (
    <>
      <Row className="storeInformationBox" gutter="24">
        <Col xl={24} xs={24}>
          <Form layout="vertical" name="" className="innerBox" form={storeForm}>
            <h1>Store Information</h1>
            <Row gutter="48">
              <Col xl={12} xs={24}>
                <Form.Item
                  label="State"
                  name="state"
                  placeholder="Select State"
                  rules={[
                    { required: true, message: "Please select your state" }
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select State"
                    size="large"
                    name="state"
                    onChange={handleStateChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                  >
                    {/* <Option value="">Select State</Option> */}

                    {campaignSelectState !== 'ALL States' && campaignSelectState.length > 0
                      ? campaignSelectState &&
                      campaignSelectState.map((item, key) => {
                        return (
                          <Option value={item.StateId} key={key}>
                            {item.stateName}
                          </Option>
                        );
                      })
                      : stateData &&
                      stateData.map((item, key) => {
                        return (
                          <Option value={item.stateId} key={key}>
                            {item.stateName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    { required: true, message: "Please select your city" }
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select City"
                    size="large"
                    name="city"
                    onChange={handleCityChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {/* <Option value="">Select City</Option> */}
                    {cityData &&
                      cityData.map((item, key) => {
                        return (
                          <Option value={item.cityId} key={key}>
                            {item.cityName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Street Address"
                  name="streetAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please input your street address"
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9-,./ ]+$/i),
                      message: "Invalid street address"
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Address"
                    allowClear
                    name="addressLine1"
                    onKeyPress={() => handleCityChange()}
                  />
                </Form.Item>
                <Form.Item
                  label="Zip Code"
                  name="zip"
                  rules={[
                    { required: true, message: "Please input your zip code" },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]{5,8}$/i),
                      message: "Invalid zip code"
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Zip code"
                    allowClear
                    name="zip"
                    onKeyPress={() => handleCityChange()}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                <Form.Item name="isEdit" hidden={true}>
                  <Input />
                </Form.Item>
                {initialLocation.lat ? (
                  <>
                    {/* <Checkbox checked={false} onClick={() => onChangeEdit(true)}>Edit Store Information</Checkbox><br /> */}
                    Set Geocoordinate
                    <div className="mapBox">
                      <MapContainer
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        initialLocation={initialLocation}
                      />
                    </div>
                  </>
                ) : (
                  <div className="fillBox" style={{ height: "348" }}>
                    <Empty
                      image={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="55.689"
                          height="74.253"
                          viewBox="0 0 55.689 74.253"
                        >
                          <path
                            id="map-marker-alt-solid"
                            d="M24.983,72.755C3.911,42.207,0,39.072,0,27.845a27.845,27.845,0,0,1,55.689,0c0,11.227-3.911,14.362-24.983,44.91a3.482,3.482,0,0,1-5.723,0Zm2.862-33.308a11.6,11.6,0,1,0-11.6-11.6A11.6,11.6,0,0,0,27.845,39.447Z"
                            fill="#30649b"
                          />
                        </svg>
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        margin: "0px auto",
                        padding: "32px 0",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        textAlign: "left"
                      }}
                      description={
                        <span>
                          <p>Please, fill in the following fields:</p>
                          <ul>
                            <li>State</li>
                            <li>City</li>
                            <li>Street Address</li>
                            <li>Zipcode</li>
                          </ul>
                        </span>
                      }
                    ></Empty>
                  </div>
                )}
                {/* <Form.Item
                                    label="Set geocordinate"
                                    name="setGeocordinate"
                                    style={{ height: '300' }}
                                >
                                    <iframe src="http://plnkr.co/" height="252" width="100%" />
                                </Form.Item> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export { Storeinformation };
