import React, {useEffect} from "react";
import {
    Link
} from "react-router-dom";
import { Avatar, Tooltip } from "antd";
import { StarFilled } from "@ant-design/icons";
import Fade from 'react-reveal/Fade';
import Whatwegothere from './Whatwegothere'
import Banner1 from '../../assets/images/Frontend/businessb.png'
import Join from '../../assets/images/Frontend/join.png'
import LoadOffer from '../../assets/images/Frontend/load offer.png'
import BusinessGrow from '../../assets/images/Frontend/business.png'
import Business1 from '../../assets/images/Frontend/reporting 03.png'
import Business2 from '../../assets/images/Frontend/reporting 01.png'
import Business3 from '../../assets/images/Frontend/reporting 04.png'
import User1 from '../../assets/images/Frontend/customer01.png'
import User2 from '../../assets/images/Frontend/customer02.png'
import User3 from '../../assets/images/Frontend/customer03.png'
import Trusticon from '../../assets/images/Frontend/Group 1071.png'
import Curvebottom from '../../assets/images/Frontend/curve bottom.svg'
import Curvetop from '../../assets/images/Frontend/curve top.svg'
import Borderup from '../../assets/images/Frontend/up border.png'
import Borderdown from '../../assets/images/Frontend/down border.png'
const Businesses = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <section id="banner" className="container-fluid banner-section d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 pt-5 ">
                            <Fade left><h1 className="pt-5 pb-2">The Fastest Way To  Get New Customers!</h1></Fade>
                            <div className='d-flex mb-5'>
                                <Fade left><Link to='/business' className='btn-app-store bg-fill mr-2'>Join now</Link></Fade>
                            </div>
                            <div className='d-flex mt-5'>
                                <Fade bottom><Avatar.Group size="large" className='me-4'>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User1} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User2} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User3} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                </Avatar.Group></Fade>
                                <div className='d-flex flex-column justify-content-center'>
                                    <Fade bottom><h5 className='mb-1'>Our Happy Customer</h5></Fade>
                                    <Fade bottom><p className='mb-0'><StarFilled style={{ fontSize: '1.2rem', color: '#FFC300' }} /> <strong style={{ fontSize: '1.2rem' }}>4.6 </strong>(10.15k Review)</p></Fade>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative py-2">
                            <Fade bottom><img src={Banner1} alt="icon" width="100%" /></Fade>
                            <Fade left><img src={Trusticon} alt="icon" className="trust-icon"/></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="b-free" className="container-fluid b-free-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 pb-5 ">
                            <Fade bottom><h2 className='text-center'>It's completely free</h2></Fade>
                        </div>
                    </div>
                    <div className='inner-box'>
                        <div className='row'>
                            <Fade bottom><div className="col-lg-4 col-12 text-center py-3">
                                <img src={Join} alt="icon" width="130" className='shadow my-4' />
                                <div className='px-4'>
                                    <h5 className='mb-2'>Join</h5>
                                    <p className='mb-0'>KardStash connects small business owners to big brands and to their consumers via discounted offers. Join and opt in to big brands promotions. </p>
                                </div>
                                <Fade left><div className="border-up"><img src={Borderup} alt="icon" width="50%"/></div></Fade>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-center py-3">
                                <img src={LoadOffer} alt="icon" width="130" className='shadow my-4' />
                                <div className='px-4'>
                                    <h5 className='mb-2'>Load offer</h5>
                                    <p className='mb-0'>Load up offers for consumers to download via the Kardstash app and the consumers redeem these offers when they spend instore.</p>
                                </div>
                                <Fade left><div className="border-down"><img src={Borderdown} alt="icon" width="50%"/></div></Fade>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12  text-center py-3">
                                <img src={BusinessGrow} alt="icon" width="130" className='shadow my-4' />
                                <div className='px-4'>
                                    <h5 className='mb-2'>Watch your business grow </h5>
                                    <p className='mb-0'>Get the customers coming to you. KardStash connects your local business with registered users across the US and Africa</p>
                                </div>
                            </div></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="brand-solution" className="container-fluid brand-solution-section pb-0 spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 text-center">
                            <Fade bottom><h2 className='text-center'>Brand Solutions</h2></Fade>
                            <Fade bottom><p className="mb-0">KardStash smart, secure, digital experiences to<br/> help drive loyalty and incremental sales for your brand.</p></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="curve-top"> <img src={Curvetop} alt="icon"/></section>
            <section id="business-section1" className="container-fluid business-section1   content">
               <div className="container align-self-center">
                    <div className='row' style={{paddingBottom: '8rem'}}>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={Business1} alt="icon" width="100%" className='img-fluid pe-5' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5 pe-5">
                            <Fade right><h4 className='heading secondary'>Business</h4></Fade>
                            <Fade right><h2>KardStash Brand <br/>Campaigns</h2></Fade>
                            <Fade right><p className='mb-4'>KardStash powers your marketing and advertising, keeping you competitive in the industry.</p></Fade>
                            <Fade right><p className='mb-4'>Receive notifications of Big brand promotions and opt in to participate and load inventory information.</p></Fade>
                        </div>
                    </div>
                    <div className='row'>
                        
                        <div className="col-lg-6 col-12 pt-5 pe-5">
                            <Fade right><h4 className='heading ternary'>Business</h4></Fade>
                            <Fade right><h2>KardStash Store <br/>Details</h2></Fade>
                            <Fade right><p className='mb-4'>View and manage offers and products available in your store.</p></Fade>

                            <Fade right><p className='mb-4'>View your inventory and get replenishment notification report.</p></Fade>
                        </div>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={Business2} alt="icon" width="100%" className='img-fluid pe-5' /></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="curve-bottom"> <img src={Curvebottom} alt="icon"/></section>
            <section id="business-section2" className="container-fluid business-section2   pb-5 content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={Business3} alt="icon" width="100%" className='img-fluid pe-5' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5 pe-5">
                            <Fade right><h4 className='heading secondary'>Business</h4></Fade>
                            <Fade right><h2>KardStash Dashboard </h2></Fade>
                            <Fade right><h4 className="mb-3">Smart Features to manage your business and power your marketing</h4></Fade>
                            <Fade right><p className='mb-4'>KardStash offers you a smart dashboard that gives you insight into your business and all transaction reports.</p></Fade>
                            <Fade right><p className='mb-4'>Manage your offers, add, edit offers and load offers and view redeemed offers.</p></Fade>
                            <Fade right><p className='mb-4'>Opt-in to big brand promotions</p></Fade>
                            <Fade right><p className='mb-4'>View and manage your promotions and inventory</p></Fade>
                            <Fade right><p className='mb-4'>Set up auto replenishment for stock</p></Fade>
                        </div>
                    </div>
                </div>
                


            </section>
            <Whatwegothere/>
        </>
    );
};
export default Businesses;