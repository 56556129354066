import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, message, Row, Col, Button } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { Loader } from "../../common/components/Loader";
import "./customer.css";

import "../../modules/public/public.less";
// const { Content } = Layout;
const CustomerView = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode,
        // type: "voucher"
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (
          response.data.campaign.Cstatus === "1" ||
          response.data.campaign.Cstatus === 1
        ) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  return (
    <div id="mobile_body">
      <Layout id="mobile_page">
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Row className="campaignDetailSec">
            {/* <Link className="navbar-brand" to="/">
              <img
                src={campaignData?.campaign?.logo}
                alt="logo"
                className="klogo d-inline"
              />
            </Link> */}
            <Col xs={24} xl={24}>
              {/* <div className="d-flex justify-content-between campaignHeader">
                <h1>
                  {campaignData ? campaignData.campaign.CampaignName : ""}
                </h1>
              </div> */}
              <div
                className="text-center"
                style={{
                  backgroundImage: "url('https://kardstash.com.dev.spointe.org/static/media/app%20home.26776581.png')",
                  backgroundSize: "100% 100%",
                  minHeight: "500px"
                }}
              >
                <Link to={`/c/${campaignCode}/save-to-phone`}>
                  <Button>SAVE TO PHONE</Button>
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Layout>
    </div>
  );
};
export default CustomerView;
