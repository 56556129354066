import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, Card, Skeleton, Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import Productlist from "./ProductList";
import { AppConfig } from "../../config/AppConfig";
import { abbreviateNumber } from "../../Helpers";

const CampaignDetail = ({ loading, campaignData, campaignId, brandData }) => {
  return (
    <Row gutter={[48, 48]} className="campaignDetailSec">
      <Col xs={24} xl={24}>
        <div className="d-flex justify-content-between campaignHeader">
          <h1>{campaignData ? campaignData.campaign.CampaignName : ""}</h1>
          <div
            className="divider"
            style={{ backgroundColor: brandData ? brandData.primary : "" }}
          ></div>
          <div>
            {campaignData &&
              moment().format("YYYY-MM-DD") > campaignData.campaign.CEndDate ? (
              <Button
                className="ant-btn"
                style={{
                  backgroundColor: brandData ? brandData.primary : "",
                  color: "#fff",
                  borderColor: brandData ? brandData.primary : ""
                }}
              >Expired</Button>
            ) : campaignData ? (
              <>
                {" "}
                <a
                  href={`${AppConfig.JOIN_LOGIN_URL}?campaignId=${campaignId}`}
                  className="joinBtn ant-btn"
                  style={{
                    marginRight: "10px",
                    backgroundColor: brandData ? brandData.secondary : "",
                    color: "#fff",
                    borderColor: brandData ? brandData.secondary : ""
                  }}
                >
                  Sign In
                </a>
                <Link
                  to={`/public/add?campaignId=${campaignId}`}
                  className="joinBtn ant-btn"
                  style={{
                    backgroundColor: brandData ? brandData.primary : "",
                    color: "#fff",
                    borderColor: brandData ? brandData.primary : ""
                  }}
                >
                  Opt In
                </Link>
              </>
            ) : ''}
          </div>
        </div>
      </Col>
      <Col xs={24} xl={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8}>
            <div
              className="countBox"
              style={{ borderLeftColor: brandData ? brandData.primary : "" }}
            >
              <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                <h1 style={{ color: brandData ? brandData.primary : "" }}>
                  {" "}
                  {campaignData ? campaignData.campaign.CardCount : ""}{" "}
                </h1>
                <p>Number of Voucher(s)</p>
              </Skeleton>
            </div>
            <div
              className="countBox"
              style={{ borderLeftColor: brandData ? brandData.secondary : "" }}
            >
              <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                <h1 style={{ color: brandData ? brandData.secondary : "" }}>
                  {brandData ? brandData.currencySign : ""}
                  {campaignData ? campaignData.campaign.Denomination : ""}
                </h1>
                <p>Denomination</p>
              </Skeleton>
            </div>
            <div
              className="countBox"
              style={{
                borderLeftColor: brandData ? brandData.tertiary : ""
              }}
            >
              <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                <h1 style={{ color: brandData ? brandData.tertiary : "" }}>
                  {brandData ? brandData.currencySign : ""}
                  {campaignData ? abbreviateNumber(campaignData.campaign.TotalOffer) : ""}
                </h1>
                <p>Total offer Value</p>
              </Skeleton>
            </div>
          </Col>
          <Col xs={24} xl={16}>
            <Row gutter={0} className="mb-5">
              <Col xs={24} xl={12}>
                <Skeleton loading={loading} active avatar>
                  <div className="dateBox">
                    <div className="startIcon">
                      <CalendarOutlined />
                    </div>
                    <div className="startdata">
                      <p className="mb-0">Start Date</p>
                      <h3 className="mb-0">
                        {campaignData
                          ? moment(campaignData.campaign.CStartDate).format(
                            "DD MMM,YYYY"
                          )
                          : ""}
                      </h3>
                    </div>
                  </div>
                </Skeleton>
              </Col>
              <Col xs={24} xl={12}>
                <Skeleton loading={loading} active avatar>
                  <div className="dateBox endDateBox">
                    <div className="startIcon">
                      <CalendarOutlined />
                    </div>
                    <div className="startdata">
                      <p className="mb-0">End Date</p>
                      <h3 className="mb-0">
                        {campaignData
                          ? moment(campaignData.campaign.CEndDate).format(
                            "DD MMM,YYYY"
                          )
                          : ""}
                      </h3>
                    </div>
                  </div>
                </Skeleton>
              </Col>
            </Row>
            <Row gutter={0}>
              <Col xs={24} xl={24} className="statesBox">
                <Card title="States">
                  <p>
                    {campaignData ? (
                      campaignData.stateCity === "ALL States" ? (
                        <span key={0}>ALL States</span>
                      ) : (
                        campaignData.stateCity.map((item, key) => {
                          return <span key={key}>{item.stateName}</span>;
                        })
                      )
                    ) : (
                      ""
                    )}
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} xl={24}>
        <Productlist loading={loading} campaignData={campaignData} />
      </Col>
    </Row>
  );
};

export { CampaignDetail };
