let data;
export const EnviornmentType = {
  DEV: "development",
  PROD: "production",
};
export const limit = 12;
export const env = EnviornmentType.DEV;
data = {
  API_ENDPOINT:
    env === EnviornmentType.DEV
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_LIVE_URL,

  API_VERSION: "",
  CAMPAIGN_URL: process.env.REACT_APP_CAMPAIGN_URL,
  JOIN_LOGIN_URL: "https://ekikart.test.spointe.org/"
};
data.DEFAULT_DATE_FORMAT = "LLL";

export const AppConfig = data;
